import { BreakpointsEnum } from '@/landing/types';
import { COLORS, TYPOGRAPHY } from '@landing/constants';
import {
  forwardRef,
  ForwardRefRenderFunction,
  InputHTMLAttributes,
  ReactNode,
  useId,
} from 'react';
import styled from 'styled-components';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  text?: string | ReactNode;
  className?: string;
}

const Radio: ForwardRefRenderFunction<HTMLInputElement, Props> = (
  { text, className, ...props },
  ref,
) => {
  const ownId = useId();
  const id = props.id ?? ownId;

  return (
    <>
      <Input type="radio" {...props} ref={ref} id={id} />
      <Label className={className} htmlFor={id}>
        {text && <Text>{text}</Text>}
      </Label>
    </>
  );
};

export default forwardRef(Radio);

const Label = styled.label`
  position: relative;
  display: inline-flex;
  align-items: center;
  ${TYPOGRAPHY.bodyMedium14}
  color: ${COLORS.grayscale600};
  cursor: pointer;

  ::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    min-width: 14px;
    min-height: 14px;
    border-radius: 50%;
    background-color: ${COLORS.white};
    border: 1px solid ${COLORS.grayscale200};
  }

  @media (max-width: ${BreakpointsEnum.sm}px) {
    font-size: 14px;
    line-height: 1.2;
  }
`;

const Input = styled.input`
  position: absolute;
  left: 0;
  opacity: 0;

  &:checked + ${Label} {
    ::before {
      border-color: ${COLORS.primary500};
      border-width: 4px;
    }
  }
`;

const Text = styled.span`
  margin-left: 8px;
  padding-left: 16px;
`;
