import { FC, HTMLAttributes, memo } from 'react';

interface Props extends HTMLAttributes<HTMLOrSVGElement> {
  color?: string;
}

const ChevronDown: FC<Props> = ({ color = 'black', ...props }) => {
  return (
    <svg
      {...props}
      width="11"
      height="6"
      viewBox="0 0 11 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M1 1L5.20019 5.20019" stroke={color} strokeLinecap="round" />
      <path
        d="M9.64648 1L5.44629 5.20019"
        stroke={color}
        strokeLinecap="round"
      />
    </svg>
  );
};

export default memo(ChevronDown);
