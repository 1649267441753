import { forwardRef } from 'react';
import styled from 'styled-components';
import Container from '../Container';
import 'swiper/css';
import 'swiper/css/free-mode';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode, Navigation } from 'swiper/modules';
import { COLORS, TYPOGRAPHY } from '@landing/constants';
import { ContentBox } from '@landing/ui/components';
import Link from 'next/link';
import GuaranteeBg from '../../assets/images/guarantee-bg.png';
import { BreakpointsEnum } from '@landing/types';
import SliderNextArrow from '@landing/ui/iconComponents/SliderNextArrow';
import apx from '@landing/constants/adaptivepx';

const GuaranteeSection = forwardRef<HTMLDivElement>((_, ref) => {
  // const [prevEl, setPrevEl] = useState<HTMLElement | null>(null);
  // const [nextEl, setNextEl] = useState<HTMLElement | null>(null);
  // const [isFirts, setFirst] = useState(false)
  // const [isEnd, setEnd] = useState(false)

  // const nextButtonRef = useRef<HTMLButtonElement>(null);
  // const prevButtonRef = useRef<HTMLButtonElement>(null);

  // const { state: isOpened, toggle: toggleOpen } = useToggle();

  return (
    <Root ref={ref}>
      <GuaranteeContainer>
        <GuaranteeTitle>Гарантия и забота</GuaranteeTitle>
        <NavigationBox>
          <button className="swiper-button-prev">
            <SliderNextArrow />
          </button>
          <button className="swiper-button-next">
            <SliderNextArrow />
          </button>
        </NavigationBox>
      </GuaranteeContainer>
      <GuaranteeSlider
        slidesPerView={'auto'}
        modules={[Navigation, FreeMode]}
        spaceBetween={19}
        freeMode={true}
        navigation={{
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        }}
      >
        <SwiperSlide>
          <ContentBox className="guaranteeInfo">
            <ContentBoxTitle>Лучшие психологи</ContentBoxTitle>
            <ContentBoxInfo>
              Высокая квалификация специалистов, прошедших{' '}
              <Link href={'#team'}> самый строгий отбор</Link> в индустрии
            </ContentBoxInfo>
          </ContentBox>
        </SwiperSlide>
        <SwiperSlide>
          <ContentBox className="guaranteeInfo">
            <ContentBoxTitle>Гарантия качества</ContentBoxTitle>
            <ContentBoxInfo>
              Если вам не подойдёт психолог, мы подберем вам другого специалиста
              и проведем повторную сессию за свой счет.
              {/* <Link href={'#'}> Как это работает?</Link> */}
            </ContentBoxInfo>
          </ContentBox>
        </SwiperSlide>
        <SwiperSlide>
          <ContentBox className="guaranteeInfo">
            <ContentBoxTitle>Высокие стандарты</ContentBoxTitle>
            <ContentBoxInfo>
              В нашу структуру входят оффлайн центры для взрослых и детей, а так
              же институт психологии, сертифицированный по стандартам
              европейской ассоциации гештальт-терапии EAGT.
            </ContentBoxInfo>
          </ContentBox>
        </SwiperSlide>
        <SwiperSlide>
          <ContentBox className="guaranteeInfo">
            <ContentBoxTitle>Безопасность</ContentBoxTitle>
            <ContentBoxInfo>
              Мы на 100% гарантируем конфиденциальность сессий и анонимность
              общения
            </ContentBoxInfo>
          </ContentBox>
        </SwiperSlide>
        <SwiperSlide>
          <ContentBox className="guaranteeInfo">
            <ContentBoxTitle>Служба заботы</ContentBoxTitle>
            <ContentBoxInfo>
              Ваш личный помощник всегда на связи 7 дней в неделю
            </ContentBoxInfo>
          </ContentBox>
        </SwiperSlide>
      </GuaranteeSlider>

      <GuaranteeContent>
        <ContentBox className="guaranteeInfo">
          <ContentBoxTitle>Лучшие психологи</ContentBoxTitle>
          <ContentBoxInfo>
            Высокая квалификация специалистов, прошедших{' '}
            <Link href={'#team'}>самый строгий отбор</Link> в индустрии
          </ContentBoxInfo>
        </ContentBox>
        <ContentBox className="guaranteeInfo">
          <ContentBoxTitle>Гарантия качества</ContentBoxTitle>
          <ContentBoxInfo>
            Если вам не подойдёт психолог, мы подберем вам другого специалиста и
            проведем повторную сессию за свой счет.
            <br />
            {/* <Link href={'#'}>Как это работает?</Link> */}
          </ContentBoxInfo>
        </ContentBox>
        <ContentBox className="guaranteeInfo">
          <ContentBoxTitle>Высокие стандарты</ContentBoxTitle>
          <ContentBoxInfo>
            В нашу структуру входят оффлайн центры для взрослых и детей, а так
            же институт психологии, сертифицированный по стандартам европейской
            ассоциации гештальт-терапии EAGT.
          </ContentBoxInfo>
        </ContentBox>
        <ContentBox className="guaranteeInfo">
          <ContentBoxTitle>Безопасность</ContentBoxTitle>
          <ContentBoxInfo>
            Мы на 100% гарантируем конфиденциальность сессий и анонимность
            общения
          </ContentBoxInfo>
        </ContentBox>
        <ContentBox className="guaranteeInfo">
          <ContentBoxTitle>Служба заботы</ContentBoxTitle>
          <ContentBoxInfo>
            Ваш личный помощник всегда на связи 7 дней в неделю
          </ContentBoxInfo>
        </ContentBox>
      </GuaranteeContent>
    </Root>
  );
});

GuaranteeSection.displayName = 'GuaranteeSection';

export default GuaranteeSection;

const Root = styled.section`
  position: relative;
  background: ${COLORS.lightBlue};
  padding: 74px 0 78px 0;
  overflow: hidden;

  &::after {
    content: '';
    position: absolute;
    width: 583px;
    height: 333px;
    right: 76px;
    top: 9px;
    background-image: url(${GuaranteeBg.src});
    background-repeat: no-repeat;
    transform: rotate(-4deg);
  }

  @media (max-width: ${BreakpointsEnum.sm}px) {
    padding-top: 55px;
    padding-bottom: 55px;

    &::after {
      height: 183px;
      width: 398px;
      top: 26px;
      left: 292px;
      background-size: cover;
    }
  }

  @media (max-width: ${BreakpointsEnum.s}px) {
    padding: 33px 0 50px 0;

    &::after {
      height: 163px;
      width: 254px;
      top: 111px;
      left: auto;
      right: -52px;
      background-size: contain;
    }

    &::before {
      content: '';
      position: absolute;
      width: 322px;
      height: 197px;
      bottom: 73px;
      left: -36px;
      background-image: url(/_next/static/media/guarantee-bg.690a6533.png);
      background-repeat: no-repeat;
      transform: rotateY(180deg);
      background-size: contain;
    }

    .guaranteeInfo {
      padding: 30px !important;
    }
  }
`;

const GuaranteeContainer = styled(Container)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: ${apx.d(42)};

  @media (max-width: ${BreakpointsEnum.tablet}px) {
    justify-content: flex-start;
    margin-bottom: ${apx.l(32)};
  }

  @media (max-width: ${BreakpointsEnum.mb}px) {
    margin-bottom: ${apx.m(26)};
  }
`;

const GuaranteeTitle = styled.h2`
  text-align: center;
  ${TYPOGRAPHY.bodyBold36}
  color: ${COLORS.nativeBlack};

  font-size: ${apx.d(48)};
  line-height: ${apx.d(59)};

  @media (max-width: ${BreakpointsEnum.tablet}px) {
    text-align: left;
    font-size: ${apx.l(36)} !important;
    line-height: ${apx.l(45)} !important;
  }

  @media (max-width: ${BreakpointsEnum.mb}px) {
    text-align: center !important;
    width: 100%;
    font-size: ${apx.m(30)} !important;
    line-height: ${apx.m(35)} !important;
  }
`;

const NavigationBox = styled.div`
  display: flex;
  position: absolute;
  right: -${apx.d(43)};
  column-gap: ${apx.d(4)};
  z-index: 10;

  .swiper-button-prev {
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${apx.d(36)};
    height: ${apx.d(36)};
    border-radius: 50%;
    cursor: pointer;
    background: ${COLORS.white};

    svg {
      transform: rotate(180deg);

      width: ${apx.d(14)};
      height: ${apx.d(10)};
    }

    &:disabled {
      opacity: 0.5;
      cursor: auto;
    }
  }

  .swiper-button-next {
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${apx.d(36)};
    height: ${apx.d(36)};
    border-radius: 50%;
    cursor: pointer;
    background: ${COLORS.white};

    svg {
      width: ${apx.d(14)};
      height: ${apx.d(10)};
    }

    &:disabled {
      opacity: 0.5;
      cursor: auto;
    }
  }

  @media (max-width: ${BreakpointsEnum.tablet}px) {
    right: ${apx.l(30)};
    column-gap: ${apx.l(3)};

    .swiper-button-prev {
      width: ${apx.l(27)};
      height: ${apx.l(27)};

      svg {
        width: ${apx.l(14)};
        height: ${apx.l(10)};
      }
    }

    .swiper-button-next {
      width: ${apx.l(27)};
      height: ${apx.l(27)};

      svg {
        width: ${apx.l(14)};
        height: ${apx.l(10)};
      }
    }
  }

  @media (max-width: ${BreakpointsEnum.mb}px) {
    display: none;
  }
`;

const GuaranteeSlider = styled(Swiper)`
  padding-left: 14.01vw;
  padding-right: 14.01vw;

  .guaranteeInfo {
    padding: ${apx.d(42)} ${apx.d(45)} ${apx.d(43)} ${apx.d(47)};
  }

  .swiper-slide {
    width: ${apx.d(346)};
  }

  @media (max-width: ${BreakpointsEnum.tablet}px) {
    .guaranteeInfo {
      padding: ${apx.l(31)};
    }

    .swiper-slide {
      width: ${apx.l(259)};
    }
  }

  @media (max-width: ${BreakpointsEnum.mb}px) {
    display: none;
  }
`;

const ContentBoxTitle = styled.h3`
  ${TYPOGRAPHY.bodyBold22}
  margin-bottom: ${apx.d(15)};
  font-size: ${apx.d(24)};
  line-height: ${apx.d(30)};
  color: ${COLORS.nativeBlack};
  user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;

  @media (max-width: ${BreakpointsEnum.tablet}px) {
    font-size: ${apx.l(18)};
    font-weight: 700;
    margin-bottom: ${apx.l(15)};
    line-height: ${apx.l(22)};
  }

  @media (max-width: ${BreakpointsEnum.mb}px) {
    font-size: ${apx.m(18)};
    margin-bottom: ${apx.m(15)};
    line-height: ${apx.m(22)};
  }
`;

const ContentBoxInfo = styled.p`
  flex-direction: column;
  ${TYPOGRAPHY.bodyBold16}
  line-height: ${apx.d(26)};
  font-weight: 500;
  color: ${COLORS.nativeBlack};
  user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;

  @media (max-width: ${BreakpointsEnum.tablet}px) {
    font-size: ${apx.l(12)};
    line-height: 165%;
  }

  a {
    color: ${COLORS.mint} !important;
    font-weight: 700;
    text-decoration: underline !important;
  }
`;

const GuaranteeContent = styled.div`
  display: none;

  @media (max-width: ${BreakpointsEnum.mb}px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 10px;

    div {
      width: 90%;

      h3 {
        font-size: ${apx.m(18)};
      }

      p {
        font-size: ${apx.m(13)};
      }
    }

    div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: max-content;
      position: relative;
      z-index: 1;
      padding: 20px 40px;
    }
  }
`;
