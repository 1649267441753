import { API_URL } from '@landing/constants';
import { Roles } from '@landing/types';

export const getSignUpLink = (role?: Roles) => {
  switch (role) {
    case 'business': {
      return API_URL.loginBusiness;
    }
    case 'therapist': {
      return API_URL.therapistSignUp;
    }
    default: {
      return API_URL.clientSignUp;
    }
  }
};
