import Modal from '@landing/ui/components/Modal';
import { useMemo, useState } from 'react';
import styled from 'styled-components';
import RequestModalContent from '../RequestModalContent';
import SuccessModalContent from '../SuccessModalContent';
import {
  UseBusinessModalEnum,
  UseBusinessModalReturn,
  UseBusinessModalState,
} from './types';

export const useBusinessModal = (): UseBusinessModalReturn => {
  const [{ isOpen, type }, setState] = useState<UseBusinessModalState>({
    isOpen: false,
  });

  const { openModal, closeModal } = useMemo(
    () => ({
      openModal: (type: UseBusinessModalEnum) =>
        setState({ isOpen: true, type }),
      closeModal: () => setState({ isOpen: false }),
    }),
    [],
  );

  const memoizedModal = useMemo(() => {
    let heading = '';
    let modalContent = null;

    switch (type) {
      case UseBusinessModalEnum.Request:
        heading = 'Оставьте заявку';
        modalContent = (
          <RequestModalContent
            onConfirm={() => openModal(UseBusinessModalEnum.Success)}
          />
        );
        break;
      case UseBusinessModalEnum.Success:
        heading = 'Ваша заявка отправлена';
        modalContent = <SuccessModalContent onConfirm={closeModal} />;
        break;
    }

    return (
      <StyledModal isOpen={isOpen} onClose={closeModal} heading={heading}>
        {modalContent}
      </StyledModal>
    );
  }, [isOpen, type]);

  return [memoizedModal, { openModal, closeModal }];
};

const StyledModal = styled(Modal)`
  && {
    max-width: 440px;
  }
`;
