import { FC, memo } from 'react';

type Props = {
  color?: string;
};

const BlackStar: FC<Props> = ({ color = 'black' }) => {
  return (
    <svg
      width="16"
      height="15"
      viewBox="0 0 16 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.75 0L9.54611 5.52786H15.3585L10.6562 8.94427L12.4523 14.4721L7.75 11.0557L3.04772 14.4721L4.84383 8.94427L0.141548 5.52786H5.95389L7.75 0Z"
        fill={color}
      />
    </svg>
  );
};

export default memo(BlackStar);
