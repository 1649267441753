import { COLORS, TYPOGRAPHY } from '@landing/constants';
import apx from '@landing/constants/adaptivepx';
import { BreakpointsEnum } from '@landing/types';
import { FC } from 'react';
import styled from 'styled-components';

type StatsBoxProps = {
  title: string;
  subtitle: string;
  className?: string;
};

export const StatsBox: FC<StatsBoxProps> = ({ className, title, subtitle }) => {
  return (
    <StatsBoxWrapper className={className}>
      <StatsBoxTitle>{title}</StatsBoxTitle>
      <StatsBoxSubtitle>{subtitle}</StatsBoxSubtitle>
    </StatsBoxWrapper>
  );
};

const StatsBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: ${COLORS.white};

  width: ${apx.d(371)};
  height: ${apx.d(167)};
  border-radius: ${apx.d(36)};
  box-shadow: 0 ${apx.d(4)} ${apx.d(55)} 0 rgba(58, 54, 219, 0.05);

  @media (max-width: ${BreakpointsEnum.tablet}px) {
    padding: 0 ${apx.l(10)};

    width: ${apx.l(200)};
    height: ${apx.l(105)};
    border-radius: ${apx.l(22)};
    box-shadow: 0 ${apx.l(4)} ${apx.l(34)} 0 rgba(58, 54, 219, 0.05);
  }

  @media (max-width: ${BreakpointsEnum.mb}px) {
    border-radius: ${apx.m(20)};
    width: ${apx.m(200)};
    height: ${apx.m(106)};
  }
`;

const StatsBoxTitle = styled.h3`
  max-width: ${apx.d(252)};
  ${TYPOGRAPHY.bodyBold36};
  color: ${COLORS.nativeBlack};

  @media (max-width: ${BreakpointsEnum.tablet}px) {
    font-size: ${apx.l(23)};
    max-width: none;

    line-height: ${apx.l(37)};
  }

  @media (max-width: ${BreakpointsEnum.mb}px) {
    font-size: ${apx.m(18)};
    line-height: ${apx.m(24)};
  }
`;

const StatsBoxSubtitle = styled.p`
  ${TYPOGRAPHY.bodyMedium20};
  line-height: ${apx.d(33)};
  color: ${COLORS.nativeBlack};

  @media (max-width: ${BreakpointsEnum.tablet}px) {
    font-size: ${apx.l(13)};
    line-height: ${apx.l(21)};
  }

  @media (max-width: ${BreakpointsEnum.mb}px) {
    font-size: ${apx.m(13)};
    line-height: ${apx.m(18)};
  }
`;
