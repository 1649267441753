import { API_URL, COLORS, TYPOGRAPHY } from '@landing/constants';
import { ERROR_TEXTS } from '@landing/constants/errorTexts';
import { INPUT_MASKS } from '@landing/constants/inputMasks';
import { REGEX } from '@landing/constants/regex';
import { Button } from '@landing/ui/components';
import Checkbox from '@landing/ui/components/Checkbox';
import Radio from '@landing/ui/components/Radio';
import TextInput from '@landing/ui/components/TextInput';
import { getInputState } from '@landing/utils/getInputState';
import { FC } from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import { transformFormData } from './helpers';
import { BreakpointsEnum } from '@/landing/types';

export interface FormValues {
  name: string;
  numberOfEmployee: number;
  username: string;
  email: string;
  phone: string;
  contactByPhone: 'email' | 'phone';
  accept: boolean;
}

interface Props {
  onConfirm?: () => void;
}

const RequestModalContent: FC<Props> = ({ onConfirm }) => {
  const {
    register,
    handleSubmit,
    formState: { errors, touchedFields, isValid },
  } = useForm<FormValues>({
    mode: 'onChange',
    defaultValues: { contactByPhone: 'email' },
  });

  const onSubmit = async (data: FormValues) => {
    if (!data.accept) return;

    try {
      const res = await fetch(API_URL.requestCompany, {
        headers: { 'Content-Type': 'application/json' },
        method: 'POST',
        body: JSON.stringify(transformFormData(data)),
      });
      console.log('onSubmit > res', res);

      if (!res.ok) throw new Error();

      onConfirm?.();
    } catch (error) {}
  };

  return (
    <Root>
      <Subtitle>
        Оставьте заявку и мы свяжемся с вами для обсуждения деталей
      </Subtitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Fieldset>
          <legend className="visually-hidden">
            Общая информация о компании
          </legend>
          <InputList>
            <InputListItem>
              <TextInput
                autoFocus
                label="Как к вам обращаться?"
                spellCheck={false}
                placeholder="Напишите ваше имя"
                {...register('name', { maxLength: 50, minLength: 3 })}
              />
            </InputListItem>
            <InputListItem>
              <TextInput
                label="Название компании*"
                spellCheck={false}
                inputState={getInputState(touchedFields, errors, 'username')}
                textError={errors.username?.message}
                isError={!!errors.username?.message}
                {...register('username', {
                  required: ERROR_TEXTS.required,
                  maxLength: { value: 50, message: ERROR_TEXTS.maxLength(50) },
                  minLength: { value: 3, message: ERROR_TEXTS.minLength(3) },
                })}
              />
            </InputListItem>
            <InputListItem>
              <TextInput
                type="number"
                min={1}
                max={1_000_000}
                label="Количество сотрудников*"
                placeholder="1"
                inputState={getInputState(
                  touchedFields,
                  errors,
                  'numberOfEmployee',
                )}
                textError={errors.numberOfEmployee?.message}
                isError={!!errors.numberOfEmployee?.message}
                {...register('numberOfEmployee', {
                  required: ERROR_TEXTS.required,
                  valueAsNumber: true,
                  min: { value: 1, message: ERROR_TEXTS.min(1) },
                  max: {
                    value: 1_000_000,
                    message: ERROR_TEXTS.max(1_000_000),
                  },
                })}
              />
            </InputListItem>
            <InputListItem>
              <TextInput
                type="tel"
                label="Номер телефона*"
                placeholder="+7 000 000 00 00"
                mask={INPUT_MASKS.phone}
                inputState={getInputState(touchedFields, errors, 'phone')}
                textError={errors.phone?.message}
                isError={!!errors.phone?.message}
                {...register('phone', {
                  required: ERROR_TEXTS.required,
                  pattern: { value: REGEX.phone, message: ERROR_TEXTS.phone },
                })}
              />
            </InputListItem>
            <InputListItem>
              <TextInput
                type="email"
                label="Электронная почта*"
                placeholder="example@mail.ru"
                inputState={getInputState(touchedFields, errors, 'email')}
                textError={errors.email?.message}
                isError={!!errors.email?.message}
                {...register('email', {
                  required: ERROR_TEXTS.required,
                  pattern: { value: REGEX.email, message: ERROR_TEXTS.email },
                })}
              />
            </InputListItem>
          </InputList>
        </Fieldset>
        <Fieldset>
          <ContactByWrapper>
            <Legend>Предпочитаемый способ связи</Legend>
            <ul>
              <RadioListItem>
                <Radio
                  text="По еmail"
                  value="email"
                  {...register('contactByPhone')}
                />
              </RadioListItem>
              <RadioListItem>
                <Radio
                  text="По номеру телефона"
                  value="phone"
                  {...register('contactByPhone')}
                />
              </RadioListItem>
            </ul>
          </ContactByWrapper>
        </Fieldset>
        <Accept>
          <Checkbox
            {...register('accept', { required: ERROR_TEXTS.required })}
            error={errors.accept?.message}
            text={
              <CheckboxText>
                Подтверждаю согласие на{' '}
                <a
                  href={API_URL.privacyPolicy}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  обработку персональных данных
                </a>
              </CheckboxText>
            }
          />
        </Accept>
        <StyledButton isLarge disabled={!isValid}>
          Оставить заявку
        </StyledButton>
        <Explanation>*Поля обязательны к заполнению</Explanation>
      </form>
    </Root>
  );
};

export default RequestModalContent;

const Root = styled.div`
  padding-top: 8px;
`;

const Subtitle = styled.p`
  color: ${COLORS.grayscale600};
  ${TYPOGRAPHY.bodyRegular16};
  margin-bottom: 32px;

  @media (max-width: ${BreakpointsEnum.sm}px) {
    font-size: 16px;
    line-height: 1.5;
  }
`;

const StyledButton = styled(Button)`
  width: 100%;
  padding: 12px;
  ${TYPOGRAPHY.bodyMedium16};
  border-radius: 12px;
  margin-bottom: 16px;
  cursor: pointer;

  @media (max-width: ${BreakpointsEnum.sm}px) {
    font-size: 16px;
    line-height: 1.5;
  }
`;

const InputList = styled.ul`
  margin-bottom: 24px;
`;

const InputListItem = styled.li`
  &:not(:last-child) {
    margin-bottom: 16px;
  }
`;

const ContactByWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
`;

const Fieldset = styled.fieldset`
  border: 0;
  padding: 0;
  margin: 0;
`;

const Legend = styled.legend`
  color: ${COLORS.black};
  ${TYPOGRAPHY.bodyMedium14};
  margin-bottom: 12px;

  @media (max-width: ${BreakpointsEnum.sm}px) {
    font-size: 14px;
    line-height: 1.2;
  }
`;

const RadioListItem = styled.li`
  &:not(:last-child) {
    margin-bottom: 12px;
  }
`;

const Accept = styled.div`
  margin-bottom: 24px;
`;

const CheckboxText = styled.span`
  position: relative;
  color: ${COLORS.grayscale500};
  ${TYPOGRAPHY.bodyRegular14};

  & > a {
    color: ${COLORS.primary500};
    text-decoration: underline;
    z-index: 1;
  }

  @media (max-width: ${BreakpointsEnum.sm}px) {
    font-size: 14px;
    line-height: 1.2;
  }
`;

const Explanation = styled.span`
  ${TYPOGRAPHY.footnoteRegular13}
  color: ${COLORS.grayscale500};

  @media (max-width: ${BreakpointsEnum.sm}px) {
    font-size: 13px;
    line-height: 1.5;
  }
`;
