import howToGet1 from '@landing/assets/images/how-to-get-1.svg';
import howToGet2 from '@landing/assets/images/how-to-get-2.svg';
import howToGet3 from '@landing/assets/images/how-to-get-3.svg';

export const slides = [
  {
    id: 0,
    number: 1,
    src: howToGet1.src,
    title: 'Подать заявку и предоставить дипломы и сертификаты',
  },
  {
    id: 1,
    number: 2,
    src: howToGet2.src,
    title: 'Пройти собеседование/ тестовую сессию',
  },
  {
    id: 2,
    number: 3,
    src: howToGet3.src,
    title: 'Начать работать',
  },
];
