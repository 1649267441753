import { FormValues } from './RequestModalContent';

export const transformFormData = (data: FormValues) => {
  const { accept, ...dataWithoutAccept } = data;

  return {
    ...dataWithoutAccept,
    phone: data.phone.split(' ').join(''),
    contactByPhone: data.contactByPhone !== 'email',
  };
};
