export enum UseBusinessModalEnum {
  Request = 'request',
  Success = 'success',
}

export type UseBusinessModalReturn = [
  JSX.Element,
  { openModal: (type: UseBusinessModalEnum) => void; closeModal: () => void },
];

export type UseBusinessModalState = {
  type?: UseBusinessModalEnum;
  isOpen: boolean;
};
