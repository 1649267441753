import * as React from 'react';

const { useEffect, useRef } = React;

type IntervalFunction = () => unknown | void;

export const useInterval = (
  callback: IntervalFunction,
  delay: number | null,
) => {
  const savedCallback = useRef<IntervalFunction | null>(null);

  useEffect(() => {
    if (delay === null) return;
    savedCallback.current = callback;
  });

  useEffect(() => {
    if (delay === null) return;
    function tick() {
      if (savedCallback.current !== null) {
        savedCallback.current();
      }
    }
    const id = setInterval(tick, delay);
    return () => clearInterval(id);
  }, [delay]);
};
