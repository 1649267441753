import { FC } from 'react';
import { COLORS, TYPOGRAPHY } from '@landing/constants';
import { BreakpointsEnum } from '@landing/types';
import styled from 'styled-components';
import Container from '../Container';
import Image from 'next/image';
import { cards } from './data';
import apx from '@landing/constants/adaptivepx';

interface Props {
  className?: string;
}

const TherapistWhyWeSection: FC<Props> = ({ className }) => {
  return (
    <Root className={className}>
      <StyledContainer>
        <Title>Почему мы</Title>
        <Cards>
          {cards.map(({ text, imgSrc }, index) => (
            <Card key={index}>
              <ImageWrap>
                <Image width={94} height={94} src={imgSrc} alt="Иконка" />
              </ImageWrap>
              <p>{text}</p>
            </Card>
          ))}
        </Cards>
      </StyledContainer>
    </Root>
  );
};

export default TherapistWhyWeSection;

const Root = styled.section`
  width: 100%;
  background: ${COLORS.dark100};
`;

const StyledContainer = styled(Container)`
  padding-top: ${apx.d(100)};
  padding-bottom: ${apx.d(142)};

  @media (max-width: ${BreakpointsEnum.tablet}px) {
    padding-top: ${apx.l(40)};
    padding-bottom: ${apx.l(75)};
  }

  @media (max-width: ${BreakpointsEnum.mb}px) {
    padding-top: ${apx.m(40)};
    padding-bottom: ${apx.m(75)};
  }
`;

const Title = styled.h2`
  margin-bottom: ${apx.d(54)};
  ${TYPOGRAPHY.heading2SemiBold64};
  color: ${COLORS.dark800};

  @media (max-width: ${BreakpointsEnum.tablet}px) {
    margin-bottom: ${apx.l(50)};
    font-size: ${apx.l(64)} !important;
    line-height: ${apx.l(64)} !important;
  }

  @media (max-width: ${BreakpointsEnum.mb}px) {
    margin-bottom: ${apx.m(50)};
    font-size: ${apx.m(36)} !important;
    line-height: ${apx.m(36)} !important;
  }
`;

const Cards = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  @media (max-width: ${BreakpointsEnum.tablet}px) {
    gap: ${apx.l(30)};
  }

  @media (max-width: ${BreakpointsEnum.mb}px) {
    gap: 0;
  }
`;

const Card = styled.div`
  width: 100%;
  max-width: ${apx.d(340)};

  p {
    ${TYPOGRAPHY.headline4Semibold24};
    text-align: center;
  }

  &:nth-of-type(-n + 3) {
    margin-bottom: 100px;
  }

  @media (max-width: ${BreakpointsEnum.tablet}px) {
    max-width: ${apx.l(320)};

    &:not(:last-of-type) {
      margin-bottom: ${apx.l(83)};
    }

    p {
      font-size: ${apx.l(24)};
      line-height: 130%;
    }
  }

  @media (max-width: ${BreakpointsEnum.mb}px) {
    max-width: none;
    width: 100%;

    &:not(:last-of-type) {
      margin-bottom: ${apx.m(83)};
    }

    p {
      font-size: ${apx.m(24)};
      line-height: 130%;
    }
  }
`;

const ImageWrap = styled.div`
  margin: 0 auto;
  margin-bottom: ${apx.d(12)};
  display: flex;
  justify-content: center;

  width: ${apx.d(94)};
  height: ${apx.d(94)};

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  @media (max-width: ${BreakpointsEnum.tablet}px) {
    width: ${apx.l(94)};
    height: ${apx.l(94)};
  }

  @media (max-width: ${BreakpointsEnum.mb}px) {
    width: ${apx.m(94)};
    height: ${apx.m(94)};
  }
`;
