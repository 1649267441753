import { COLORS, TYPOGRAPHY } from '@landing/constants';
import apx from '@landing/constants/adaptivepx';
import { BreakpointsEnum } from '@landing/types';
import { ITherapist } from '@landing/types/home.page';
import { FC, ReactNode, memo, useMemo } from 'react';
import styled from 'styled-components';

type TherapistCardProps = {
  data: ITherapist;
  children: ReactNode;
  isVideo: boolean;
};

const TherapistCardCompoent: FC<TherapistCardProps> = ({
  data,
  children,
  isVideo,
}) => {
  const {
    firstname,
    lastname,
    videoKey,
    avatarKey,
    closestFreeAvailability,
    workingExperience,
    totalSessions,
  } = data;

  const imgUrl = avatarKey;
  const cardBg = {
    backgroundImage: `url(${imgUrl})`,
  };

  const date = formatDate(closestFreeAvailability.startDate);

  const renderCardVariant = useMemo(() => {
    if (!isVideo || videoKey === null) {
      return (
        <Card style={cardBg}>
          <Name>{`${firstname} ${lastname}`}</Name>

          <Time>{date}</Time>

          <Info>
            <span>{formatYears(workingExperience) + ' практики'}</span>
            {totalSessions ? (
              <span>{totalSessions + '+' + ' сессий'}</span>
            ) : (
              ''
            )}
          </Info>

          {children}
        </Card>
      );
    } else {
      return (
        <>
          <VideoWrapper src={videoKey} autoPlay loop muted />

          <Card>
            <Name>{`${firstname} ${lastname}`}</Name>
            <Time>{date}</Time>
            <Info>
              <span>{formatYears(workingExperience) + ' практики'}</span>
              {totalSessions ? (
                <span>{totalSessions + '+' + ' сессий'}</span>
              ) : (
                ''
              )}
            </Info>

            {children}
          </Card>
        </>
      );
    }
  }, [videoKey]);

  return (
    // <Card style={cardBg}>
    //   <Name>{name}</Name>
    //   <Time>{time}</Time>
    //   <Info>
    //     <span>{experience}</span>
    //     <span>{sessions}</span>
    //   </Info>
    //   {children}
    // </Card>
    <>{renderCardVariant}</>
  );
};

export const TherapistCard: FC<TherapistCardProps> = memo(
  TherapistCardCompoent,
);

//TODO: убрать в helpers
function formatDate(dateString: string): string {
  const date = new Date(dateString);

  // Определяем текущую дату
  const today = new Date();
  today.setUTCHours(0, 0, 0, 0);

  // Определяем дату завтра
  const tomorrow = new Date(today.getTime() + 24 * 60 * 60 * 1000);

  // Определяем дату послезавтра
  const afterTomorrow = new Date(tomorrow.getTime() + 24 * 60 * 60 * 1000);

  // Сравниваем переданную дату с текущей датой
  if (date >= today && date < tomorrow) {
    return 'Сегодня ' + date.toLocaleTimeString('ru-RU').slice(0, 5);
  } else if (date >= tomorrow && date < afterTomorrow) {
    return 'Завтра ' + date.toLocaleTimeString('ru-RU').slice(0, 5);
  } else {
    return (
      date.toLocaleDateString('ru-RU').slice(0, 5) +
      ' - ' +
      date.toLocaleTimeString('ru-RU').slice(0, 5)
    );
  }
}
function formatYears(age: number) {
  if (age < 1 || age > 99) {
    return 'Ошибка! Введите число от 1 до 99.';
  }

  const yearsString = age.toString();

  // Получение последних двух цифр из числа
  const lastTwoDigits = parseInt(yearsString.slice(-2));

  // Определение соответствующего окончания
  let suffix;
  if (lastTwoDigits >= 11 && lastTwoDigits <= 19) {
    suffix = 'лет';
  } else {
    const lastDigit = parseInt(yearsString.charAt(yearsString.length - 1));
    if (lastDigit === 1) {
      suffix = 'год';
    } else if (lastDigit >= 2 && lastDigit <= 4) {
      suffix = 'года';
    } else {
      suffix = 'лет';
    }
  }

  return yearsString + ' ' + suffix;
}

const Card = styled.div`
  width: ${apx.d(314)};
  border-radius: ${apx.d(41)};
  padding: 0 ${apx.d(45)} ${apx.d(29)} ${apx.d(33)};
  height: ${apx.d(396)};

  position: relative;
  display: flex;
  background-position: center;
  background-size: cover;
  flex-direction: column;
  justify-content: flex-end;

  &::after {
    content: '';
    position: absolute;
    display: block;
    background: rgba(31, 31, 31, 0.4);

    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 1;
    border-radius: ${apx.d(41)};
  }

  @media (max-width: ${BreakpointsEnum.tablet}px) {
    width: ${apx.l(200)};
    height: ${apx.l(250)};
    padding: 0 ${apx.l(28)} ${apx.l(18)} ${apx.l(19)};
    border-radius: ${apx.l(26)} !important;

    &::after {
      border-radius: ${apx.l(26)} !important;
    }
  }

  @media (max-width: ${BreakpointsEnum.mb}px) {
    width: ${apx.m(198)};
    height: ${apx.m(259)};

    border-radius: ${apx.m(26)} !important;
    padding: 0 ${apx.m(15)} ${apx.m(29)} ${apx.m(19)};

    &::after {
      border-radius: ${apx.m(26)} !important;
    }
  }

  a {
    position: absolute;
    bottom: -${apx.d(14)};
    font-size: ${apx.d(13)};
    line-height: ${apx.d(16)};
    box-shadow: 0 0 ${apx.d(61)} 0 rgba(0, 0, 0, 0.25);
    padding: ${apx.d(7)} ${apx.d(24)};
    border-radius: ${apx.d(61)};

    left: 0;
    right: 0;
    margin: 0 auto;
    width: fit-content;
    background: ${COLORS.white};
    font-weight: 600;
    color: ${COLORS.nativeBlack};
    z-index: 10;

    @media (max-width: ${BreakpointsEnum.tablet}px) {
      font-size: ${apx.l(10)};
      line-height: auto;
      padding: ${apx.l(5)} ${apx.l(17)} !important;

      bottom: -${apx.l(9)};
      box-shadow: 0 0 ${apx.l(61)} 0 rgba(0, 0, 0, 1);
      border-radius: ${apx.l(61)};
    }

    @media (max-width: ${BreakpointsEnum.mb}px) {
      font-size: ${apx.m(11)};
      line-height: auto;
      padding: ${apx.m(10)} ${apx.m(20)} !important;

      bottom: -${apx.m(9)};
      box-shadow: 0 0 ${apx.m(61)} 0 rgba(0, 0, 0, 0.5);
      border-radius: ${apx.m(61)};
    }
  }
`;

const Name = styled.h3`
  ${TYPOGRAPHY.bodyBold16}
  font-size: ${apx.d(21)};
  line-height: ${apx.d(26)};
  margin-bottom: ${apx.d(3)};

  color: ${COLORS.white};
  z-index: 20;

  @media (max-width: ${BreakpointsEnum.tablet}px) {
    font-size: ${apx.l(13)};
    line-height: ${apx.l(15)};

    margin-bottom: ${apx.l(3)};
  }

  @media (max-width: ${BreakpointsEnum.mb}px) {
    font-size: ${apx.m(14)};
    line-height: ${apx.m(18)};

    margin-bottom: ${apx.m(3)};
  }
`;

const Time = styled.p`
  ${TYPOGRAPHY.bodyMedium12}
  font-size:  ${apx.d(15)};
  line-height: ${apx.d(18)};
  margin-bottom: ${apx.d(12)};

  color: ${COLORS.white};
  z-index: 10;

  @media (max-width: ${BreakpointsEnum.tablet}px) {
    font-size: ${apx.l(10)};
    line-height: ${apx.l(12)};
    margin-bottom: ${apx.l(7)};
  }

  @media (max-width: ${BreakpointsEnum.mb}px) {
    font-size: ${apx.m(11)};
    line-height: ${apx.m(13)};
    margin-bottom: ${apx.m(7)};
  }
`;

const Info = styled.div`
  display: flex;
  column-gap: ${apx.d(7)};
  z-index: 20;

  span {
    border-radius: ${apx.d(41)};
    height: ${apx.d(21)};
    border: ${apx.d(1)} solid ${COLORS.white};
    font-size: ${apx.d(13)} !important;
    line-height: ${apx.d(16)} !important;

    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    ${TYPOGRAPHY.bodyBold16}
    color: ${COLORS.white};

    @media (max-width: ${BreakpointsEnum.tablet}px) {
      font-size: ${apx.l(8)} !important;
      min-width: ${apx.l(72)};
      height: ${apx.l(17)};
      line-height: ${apx.l(12)};

      border-radius: ${apx.l(41)};
      border: ${apx.l(1)} solid ${COLORS.white};
    }

    @media (max-width: ${BreakpointsEnum.mb}px) {
      font-size: ${apx.m(8)} !important;
      min-width: ${apx.m(72)};
      height: ${apx.m(17)};
      line-height: ${apx.m(12)};

      border-radius: ${apx.m(41)};
      border: ${apx.m(1)} solid ${COLORS.white};
    }
  }
`;

const VideoWrapper = styled.video`
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: ${apx.d(41)};

  @media (max-width: ${BreakpointsEnum.tablet}px) {
    border-radius: ${apx.l(26)} !important;

    &::after {
      border-radius: ${apx.l(26)} !important;
    }
  }

  @media (max-width: ${BreakpointsEnum.mb}px) {
    border-radius: ${apx.m(26)} !important;

    &::after {
      border-radius: ${apx.m(26)} !important;
    }
  }
`;
