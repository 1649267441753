import { FC, useRef } from 'react';
import styled from 'styled-components';
import { ParallaxBanner } from 'react-scroll-parallax';
import hsTherapistBg from '@landing/assets/images/hs-therapist-bg.svg';
import hsTherapistBgMobile from '@landing/assets/images/hs-therapist-bg-mobile.svg';
import { useClientSize } from '@landing/hooks';
import Container from '../Container';
import { BreakpointsEnum } from '@landing/types';
import { API_URL, COLORS, TYPOGRAPHY } from '@landing/constants';
import { ButtonLink } from '@landing/ui/components';
import { useIntersectionObserver } from '@landing/hooks/useIntersectionObserver';
import { useRouter } from 'next/router';
import apx from '@landing/constants/adaptivepx';

interface Props {
  className?: string;
}

const TherapistHeroSection: FC<Props> = ({ className }) => {
  const { getIsBreakpoint } = useClientSize();
  const isMobile = getIsBreakpoint('s');
  const rootRef = useRef(null);
  const entry = useIntersectionObserver(rootRef, {});
  const router = useRouter();

  return (
    <Root ref={rootRef} className={className}>
      <ParallaxBannerWrapper>
        <StyledParallaxBanner
          layers={[
            {
              image: isMobile ? hsTherapistBgMobile.src : hsTherapistBg.src,
              speed: 10,
              disabled: !entry?.isIntersecting,
            },
          ]}
        />
      </ParallaxBannerWrapper>
      <StyledContainer>
        <Title>Специалистам</Title>
        <StyledButtonLink
          isLarge
          href={API_URL.therapistSignUp}
          query={router.query}
          target="_blank"
          rel="noopener noreferrer"
        >
          Заполнить анкету
        </StyledButtonLink>
      </StyledContainer>
    </Root>
  );
};

export default TherapistHeroSection;

const Root = styled.section`
  background: ${COLORS.dark100};
  position: relative;
  overflow: hidden;
`;

const ParallaxBannerWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  width: 100%;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
`;

const StyledParallaxBanner = styled(ParallaxBanner)`
  height: 100%;
  width: 100%;
`;

const StyledContainer = styled(Container)`
  padding: ${apx.d(209)} 0 ${apx.d(375)} ${apx.d(269)};
  position: relative;
  z-index: 2;

  @media (max-width: ${BreakpointsEnum.tablet}px) {
    padding: ${apx.l(209)} 0 ${apx.l(375)} ${apx.l(35)};
  }

  @media (max-width: ${BreakpointsEnum.mb}px) {
    padding: ${apx.m(147)} ${apx.m(20)} ${apx.m(283)} ${apx.m(20)};
  }
`;

const Title = styled.h1`
  margin-bottom: ${apx.d(46)};
  ${TYPOGRAPHY.heading1SemiBold72};
  color: ${COLORS.dark700};

  @media (max-width: ${BreakpointsEnum.tablet}px) {
    margin-bottom: ${apx.l(46)};
    font-size: ${apx.l(60)};
    line-height: ${apx.l(60)};
  }

  @media (max-width: ${BreakpointsEnum.mb}px) {
    margin-bottom: ${apx.m(46)};
    font-size: ${apx.m(36)};
    line-height: ${apx.m(36)};
  }
`;

const StyledButtonLink = styled(ButtonLink)`
  padding: ${apx.d(20)} ${apx.d(70)};

  @media (max-width: ${BreakpointsEnum.tablet}px) {
    padding: ${apx.l(18)} ${apx.l(60)};
    font-size: ${apx.l(24)};
    line-height: 130%;
    border-radius: ${apx.l(24)};
  }

  @media (max-width: ${BreakpointsEnum.mb}px) {
    padding: ${apx.m(18)} ${apx.m(60)};
    font-size: ${apx.m(20)};
    line-height: 130%;
    border-radius: ${apx.m(24)};
  }
`;
