import user from '@landing/assets/images/user.svg';
import coins from '@landing/assets/images/coins.svg';
import heart from '@landing/assets/images/heart.svg';
import chartLineUp from '@landing/assets/images/chart-line-up.svg';
import clipboardText from '@landing/assets/images/clipboard-text.svg';
import clockCounterClockwise from '@landing/assets/images/clock-counter-clockwise.svg';

export const cards = [
  {
    text: 'Возможность устанавливать желаемую цену',
    imgSrc: coins.src,
  },
  {
    text: 'Опираемся на имя и опыт офлайн центра «Talk to me»',
    imgSrc: clipboardText.src,
  },
  {
    text: 'Удобный ЛК с возможностью отслеживать статистику',
    imgSrc: chartLineUp.src,
  },
  {
    text: 'Комфорт и соблюдение этики не только для клиента, но и для специалиста',
    imgSrc: heart.src,
  },
  {
    text: 'Оперативная и удобная техподдержка',
    imgSrc: clockCounterClockwise.src,
  },
  {
    text: 'Стабильный поток разнообразных клиентов',
    imgSrc: user.src,
  },
];
