import { FC, useState } from 'react';
import Link from 'next/link';
import styled, { css } from 'styled-components';
import Container from '../Container';
import { TYPOGRAPHY, COLORS, API_URL } from '@landing/constants';
import {
  BusinessLogo,
  ClientLogo,
  TherapistLogo,
} from '@landing/ui/iconComponents';
import { BreakpointsEnum, Roles } from '@landing/types';
import { Buttons, Menu } from './components';
import { useClientSize } from '@landing/hooks';
import { useRouter } from 'next/router';
import apx from '@landing/constants/adaptivepx';

interface Props {
  role?: Roles;
}

const Header: FC<Props> = ({ role }) => {
  const [isActiveBtn, setIsActiveBtn] = useState(false);
  const { getIsBreakpoint } = useClientSize();
  const isMobile = getIsBreakpoint('mb');

  const router = useRouter();

  return (
    <Root>
      <StyledContainer>
        <BurgerWrapper>
          <BurgerBtn
            $isActive={isActiveBtn}
            onClick={(e: any) => {
              setIsActiveBtn(!isActiveBtn);
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            <BurgerLine />
          </BurgerBtn>
        </BurgerWrapper>
        <Wrapper>
          <LinksWrap>
            {/* {role == 'client' && (
              <Link
                href={{ pathname: API_URL.client, query: router.query }}
                passHref
              >
                <a>Подбор терапевта</a>
              </Link>
            )} */}

            {role !== 'therapist' && (
              <Link
                href={{ pathname: API_URL.therapist, query: router.query }}
                passHref
              >
                <a>Психологам</a>
              </Link>
            )}
            {' '}
            <noscript>
              <br />
            </noscript>
            {role !== 'business' && (
              <Link
                href={{ pathname: 'https://b2b.pogovorim.online/', query: router.query }}
                passHref
              >
                <a>Для бизнеса</a>
              </Link>
            )}
          </LinksWrap>
          <Link href={{ pathname: '/', query: router.query }} passHref>
            <LogoLink>
              {role === 'client' && <StyledClientLogo />}
              {role === 'business' && <StyledBusinessLogo />}
              {role === 'therapist' && <StyledTherapistLogo />}
            </LogoLink>
          </Link>
          <StyledButtons isMobile={isMobile} role={role} />
        </Wrapper>
        {isActiveBtn && <Menu setIsActive={setIsActiveBtn} role={role} />}
      </StyledContainer>
    </Root>
  );
};

export default Header;
const Root = styled.header`
  position: fixed;
  width: 100%;
  display: flex;
  align-items: center;
  background: ${COLORS.white};
  z-index: 101;
  padding-top: ${apx.d(25)};
  padding-bottom: ${apx.d(25)};

  @media (max-width: ${BreakpointsEnum.sm}px) {
    padding-top: ${apx.l(18)};
    padding-bottom: ${apx.l(18)};
  }
`;

const StyledContainer = styled(Container)`
  display: flex;
  align-items: center;

  @media (max-width: ${BreakpointsEnum.lgm}px) {
    justify-content: space-between;
  }
`;

const StyledClientLogo = styled(ClientLogo)`
  min-width: ${apx.d(126)};
  height: ${apx.d(38)};
  margin-right: -${apx.d(54)};

  @media (max-width: ${BreakpointsEnum.lg}px) {
    margin-right: 0;
  }
  @media (max-width: ${BreakpointsEnum.sm}px) {
    margin-right: 0;
  }

  @media (max-width: ${BreakpointsEnum.tablet}px) {
    min-width: ${apx.l(119)};
    height: ${apx.l(36)};
  }

  @media (max-width: ${BreakpointsEnum.mb}px) {
    min-width: ${apx.m(119)};
    height: ${apx.m(36)};
  }
`;

const StyledTherapistLogo = styled(TherapistLogo)`
  min-width: ${apx.d(105)};
  height: ${apx.d(36)};
  margin: 0 auto;

  @media (max-width: ${BreakpointsEnum.tablet}px) {
    margin-right: 0;
    min-width: ${apx.l(105)} !important;
    height: ${apx.l(36)} !important;
  }

  @media (max-width: ${BreakpointsEnum.mb}px) {
    margin-right: 0;
    min-width: ${apx.m(105)} !important;
    height: ${apx.m(36)} !important;
  }
`;

const StyledBusinessLogo = styled(BusinessLogo)`
  width: ${apx.d(105)};
  height: ${apx.d(36)};
  margin: 0 auto;

  @media (max-width: ${BreakpointsEnum.tablet}px) {
    margin-right: 0;
    width: ${apx.l(105)};
    height: ${apx.l(36)};
  }

  @media (max-width: ${BreakpointsEnum.mb}px) {
    width: ${apx.m(105)};
    height: ${apx.m(36)};
  }
`;

const LinksWrap = styled.div`
  display: flex;
  /* margin-right: 245px; */

  a {
    ${TYPOGRAPHY.bodySemibold162};
    color: ${COLORS.nativeBlack};

    & > span {
      color: ${COLORS.primary500};
    }
  }

  a:not(:last-of-type) {
    margin-right: ${apx.d(32)};
    @media (max-width: 1050px) {
      margin-right: ${apx.d(17)};
    }
  }

  @media (max-width: ${BreakpointsEnum.tablet}px) {
    margin: 0;
    justify-content: flex-start;
    display: none;
  }
`;

const StyledButtons = styled(Buttons)`
  /* margin-left: auto; */
  display: flex;
  align-items: center;
`;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: ${BreakpointsEnum.lgm}px) {
    /* display: none; */
  }
`;

const BurgerWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  order: 4;
`;

const BurgerLine = styled.span`
  height: ${apx.l(2)};
  display: block;
  background: ${COLORS.nativeBlack};
  transition: width 0.25s;

  @media (max-width: ${BreakpointsEnum.mb}px) {
    height: ${apx.m(2)};
  }
`;

const BurgerBtn = styled.button<{ $isActive: boolean }>`
  position: absolute;
  width: ${apx.l(31)};
  height: ${apx.l(23)};
  display: none;
  position: relative;
  z-index: 20;
  right: 0;

  &:before {
    content: '';
    height: ${apx.l(2)};
    width: ${apx.l(31)};
    background: ${COLORS.nativeBlack};
    transform-origin: right center;
    position: absolute;
    left: 0px;
    top: 0;
    transition: transform 0.25s;
  }

  &:after {
    content: '';
    height: ${apx.l(2)};
    width: ${apx.l(31)};
    background: ${COLORS.nativeBlack};
    transform-origin: right center;
    position: absolute;
    left: 0px;
    bottom: 0;
    transition: transform 0.25s;
  }

  span {
    width: ${(props) => (props.$isActive ? '0px' : apx.l(31))};
  }

  ${({ $isActive }) => {
    if ($isActive) {
      return css`
        &:before {
          transform: rotate(-45deg);
        }

        &:after {
          transform: rotate(45deg);
        }
      `;
    }

    if (!$isActive) {
      return css`
        &:before {
          transform: rotate(0deg);
        }

        &:after {
          transform: rotate(0deg);
        }
      `;
    }
  }}

  @media (max-width: ${BreakpointsEnum.tablet}px) {
    display: block;
  }

  @media (max-width: ${BreakpointsEnum.mb}px) {
    width: ${apx.m(31)};
    height: ${apx.m(23)};

    &:before {
      height: ${apx.m(2)};
      width: ${apx.m(31)};
    }

    &:after {
      height: ${apx.m(2)};
      width: ${apx.m(31)};
    }

    span {
      width: ${(props) => (props.$isActive ? '0px' : apx.m(31))};
    }
  }
`;

const LogoLink = styled.a`
  /* width: calc(106px + (142 - 106) * ((100vw - 1200px) / (1440 - 1200)));
  margin-right: 30px; */
`;

// const IconWrap = styled.div`

//   width: ${ apx.d( 40 ) };
//   height: ${ apx.d( 40 ) };
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   margin-right: ${ apx.d( 28 ) };
//   background-color: ${COLORS.primary50};
//   border-radius: ${ apx.d( 40 ) };

// `;
