import { COLORS, TYPOGRAPHY } from '@landing/constants';
import { FC, ReactNode } from 'react';
import styled from 'styled-components';
import Container from '../Container';
import purpleFlower from '@landing/assets/images/purple-flower.svg';
import { Accordion } from './components';
import { faqList } from './data';
import { BreakpointsEnum } from '@landing/types';
import { useClientSize } from '@landing/hooks';
import apx from '@landing/constants/adaptivepx';
import ReactDomServer from 'react-dom/server';
import splitArrayToTwoColumns from '@landing/utils/splitArrayToTwoColumns';

interface IQuestionLd {
  '@type': 'Question';
  name: string;

  acceptedAnswer: {
    '@type': 'Answer';
    text: string | ReactNode;
  };
}

const FaqSection: FC = () => {
  const { getIsBreakpoint } = useClientSize();

  const isMobile = getIsBreakpoint('s');

  const questions: IQuestionLd[] = [];

  faqList.forEach((item) => {
    questions.push({
      '@type': 'Question',
      name: item.title,

      acceptedAnswer: {
        '@type': 'Answer',
        text:
          typeof item.text === 'string'
            ? item.text
            : ReactDomServer.renderToStaticMarkup(item.text),
      },
    });
  });

  const jsonLd = {
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
    mainEntity: questions,
  };

  return (
    <div id="faq" style={{ scrollMargin: 100 }}>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(jsonLd) }}
      />

      <Root $bgFlower={purpleFlower.src} id="faq">
        <StyledContainer>
          <Title>
            {isMobile ? 'Полезные ответы' : 'Вы нас часто спрашиваете'}
          </Title>

          <ContentWrap>
            <Accordion.Provider>
              {splitArrayToTwoColumns(faqList).map((columnItem, index) => (
                <Column key={index}>
                  {columnItem.map(({ title, text }, index) => (
                    <StyledAccordion
                      key={`${title}-${index}`}
                      id={`${title}-${index}`}
                      title={title}
                      textContent={text}
                    />
                  ))}
                </Column>
              ))}
            </Accordion.Provider>
          </ContentWrap>
        </StyledContainer>
      </Root>
    </div>
  );
};

export default FaqSection;

const Root = styled.section<{ $bgFlower: string }>`
  background: ${COLORS.white};
  position: relative;
  overflow: hidden;

  &:after {
    content: '';
    width: ${apx.d(274)};
    height: ${apx.d(348)};
    background-image: ${({ $bgFlower }) => `url(${$bgFlower})`};
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    position: absolute;
    bottom: 0;
    right: 24.896vw;
  }

  @media (max-width: ${BreakpointsEnum.tablet}px) {
    &:after {
      width: ${apx.l(220)};
      height: ${apx.l(236)};
      right: ${apx.l(50)};
      bottom: 0px;
      background-size: contain;
    }
  }

  @media (max-width: ${BreakpointsEnum.mb}px) {
    &:after {
      width: ${apx.m(128)};
      height: ${apx.m(163)};
      right: ${apx.m(50)};
      bottom: -${apx.m(47)};
      background-size: contain;
    }
  }
`;

const StyledContainer = styled(Container)`
  padding-top: ${apx.d(100)};
  padding-bottom: ${apx.d(76)};

  @media (max-width: ${BreakpointsEnum.tablet}px) {
    padding-top: 0;
    padding-bottom: ${apx.l(34)};
  }

  @media (max-width: ${BreakpointsEnum.mb}px) {
    padding-top: 0;
    padding-bottom: ${apx.m(100)};
  }
`;

const Title = styled.h2`
  margin-bottom: ${apx.d(40)};
  ${TYPOGRAPHY.heading5Semibold48};
  line-height: ${apx.d(62)};
  color: ${COLORS.nativeBlack};

  @media (max-width: ${BreakpointsEnum.tablet}px) {
    margin-bottom: ${apx.l(30)};
    font-size: ${apx.l(36)};
    line-height: 130%;
  }

  @media (max-width: ${BreakpointsEnum.mb}px) {
    font-size: ${apx.m(30)};
    margin-bottom: ${apx.m(16)};
    line-height: 130%;
    text-align: center;
    font-weight: 700;
  }
`;

const ContentWrap = styled.div`
  display: flex;

  @media (max-width: ${BreakpointsEnum.mb}px) {
    flex-direction: column;
  }
`;

const Column = styled.div`
  width: 100%;

  &:not(:last-of-type) {
    margin-right: ${apx.d(72)};
  }

  @media (max-width: ${BreakpointsEnum.tablet}px) {
    &:not(:last-of-type) {
      margin-right: ${apx.l(24)};
    }
  }
`;

const StyledAccordion = styled(Accordion.Item)`
  margin-bottom: ${apx.d(24)};
  justify-content: center;
  -webkit-tap-highlight-color: transparent;

  @media (max-width: ${BreakpointsEnum.tablet}px) {
    margin-bottom: ${apx.l(18)};
  }

  @media (max-width: ${BreakpointsEnum.mb}px) {
    margin-bottom: ${apx.m(24)};
  }
`;
