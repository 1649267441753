import React, { useEffect, useRef, useState } from 'react';
import { DropdownContainer, DropdownButton, DropdownList, DropdownItem } from './SelectSpecialist.styles';


interface Option {

  label: string;
  isActive: boolean;
  path: string;

}

interface SelectSpecialistProps {

  options: Option[];
  customStyles?: any;
  dropdownListStyles?: React.CSSProperties;

}

const SelectSpecialist: React.FC<SelectSpecialistProps> = ({ options, customStyles, dropdownListStyles }) => {

const [ isOpen, setIsOpen ] = useState( false );
const dropdownRef = useRef<HTMLDivElement | null>(null);
const toggleDropdown = () => setIsOpen( !isOpen );

const handleClickOutside = (event: MouseEvent) => {

  if ( dropdownRef.current && !dropdownRef.current.contains(event.target as Node) ) {

    setIsOpen( false );

  }

};

useEffect(() => {

  document.addEventListener('mousedown', handleClickOutside);

  return () => {

    document.removeEventListener('mousedown', handleClickOutside);

  };

}, []);

const handleItemClick = ( option: Option ) => {

  if ( option.isActive ) {

    setIsOpen( false );
    window.location.href = option.path;

  }

  };

  return (

    <DropdownContainer ref = { dropdownRef } style={customStyles}>

      <DropdownButton

        onClick = { (e: React.MouseEvent) => {

          e.preventDefault();
          toggleDropdown();

        } }

      >

        Выбрать специалиста

      </DropdownButton>

      { isOpen && (

        <DropdownList style={dropdownListStyles}>

          { options.map( option => (

            <DropdownItem

              key = { option.label }
              isActive = { option.isActive }

              onClick = { (e: React.MouseEvent) => {

                e.preventDefault();
                handleItemClick(option);

              } }

            >

              { option.label }

            </DropdownItem>

          )) }

        </DropdownList>

      ) }

    </DropdownContainer>

  );

};

export default SelectSpecialist;
