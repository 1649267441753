export const stagesList = [
  {
    number: '1.',
    heading: 'Проверка квалификации',
    lists: [
      {
        content: 'диплом о высшем образовании по профилю "Психология" ',
      },
      {
        content:
          'сертификация в одном из современных методов терапии не менее 1100 часов',
      },
      {
        content: 'подтвержденные 150 часов личной терапии',
      },
      {
        content: 'супервизии и еженедельная личная терапия',
      },
      {
        content: 'опыт работы не менее 3-х лет',
      },
    ],
  },

  {
    number: '3.',
    heading: 'Тестовая сессия',
    subheading:
      'Мы применяем уникальный метод отбора -  проводим тестовую сессию кандидата с  реальным клиентом, за которой наблюдает  комиссия из опытных психологов, входящих в  команду Поговорим.online',
  },

  {
    number: '2.',
    heading: 'Проверка личных качеств',
    subheading: 'Наши требования:',
    lists: [
      {
        content: 'развитые коммуникативные навыки и высокий уровень эмпатии',
      },

      {
        content: 'стремление к развитию в профессии',
      },
    ],
  },

  {
    number: '4.',
    heading: 'Принятие решения',
    subheading:
      'По завершении всех этапов комиссия анализирует работу  кандидата и принимает решение о допуске психолога на платформу.',
  },
];
