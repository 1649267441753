import { Forbes, Psychologies, RBK, Sostav } from '@landing/ui/iconComponents';
import styled from 'styled-components';
import Container from '@landing/components/Container';
import apx from '@landing/constants/adaptivepx';
import { BreakpointsEnum } from '@landing/types';
import { COLORS } from '@landing/constants';

const WriteAboutUs = () => {
  const LINK = {
    FORBES:
      'https://www.forbes.ru/biznes/510736-shkulev-media-kupil-dolu-v-servise-psihologiceskih-uslug-pogovorim-online?utm_source=forbes&utm_campaign=lnews',
    SOSTAV: 'https://www.sostav.ru/publication/shkulev-media-sdelka-67325.html',
    PSYCHO:
      'https://www.psychologies.ru/articles/novoe-slovo-ot-zumerov-kogo-molodezh-nazyvaet-ankami-i-chto-eto-znachit/',
    RBK: 'https://www.rbc.ru/technology_and_media/18/04/2024/6620f8779a7947c8c42ba336',
  };

  return (
    <Root>
      <MainContent>
        <a href={LINK.FORBES} target="_blank" rel="noreferrer">
          <Forbes />
        </a>

        <a href={LINK.SOSTAV} target="_blank" rel="noreferrer">
          <Sostav />
        </a>

        <a href={LINK.PSYCHO} target="_blank" rel="noreferrer">
          <Psychologies />
        </a>

        <a href={LINK.RBK} target="_blank" rel="noreferrer">
          <RBK />
        </a>
      </MainContent>
    </Root>
  );
};

const Root = styled.footer`
  width: 100%;
  background-color: ${COLORS.white};
`;

const MainContent = styled(Container)`
  width: 100%;
  padding-top: ${apx.d(50)};
  padding-bottom: ${apx.d(50)};
  display: flex;
  align-items: center;
  justify-content: space-around;

  @media (max-width: ${BreakpointsEnum.tablet}px) {
    padding: ${apx.l(31)} ${apx.l(31)} ${apx.l(41)} ${apx.l(35)} !important;

    display: flex;
    flex-direction: column;
    gap: ${apx.m(25)};
  }

  @media (max-width: ${BreakpointsEnum.mb}px) {
    padding: ${apx.m(31)} ${apx.m(31)} ${apx.m(54)} ${apx.m(35)} !important;

    display: flex;
    flex-direction: column;
    gap: ${apx.m(25)};
  }
`;

export default WriteAboutUs;
