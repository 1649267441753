/* eslint-disable @typescript-eslint/quotes */

import { FC, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { Button, ButtonLink } from '@landing/ui/components';
import { API_URL, COLORS, TYPOGRAPHY } from '@landing/constants';
import {
  useBusinessModal,
  UseBusinessModalEnum,
} from '@landing/pageComponents/BusinessPage/useBusinessModal';

import { BreakpointsEnum, Roles } from '@landing/types';
import { getSignUpLink } from '../../lib/getSignUpLink';
import { useRouter } from 'next/router';
import { WhatsApp } from '@landing/ui/iconComponents';
import apx from '@landing/constants/adaptivepx';
import SelectSpecialist from '@/landing/components/SelectSpecialist';

interface Props {
  className?: string;
  isMobile?: boolean;
  role?: Roles;
}

const Buttons: FC<Props> = ({ className, role, isMobile = false }) => {
  const [modal, { openModal }] = useBusinessModal();

  const signUpLink = getSignUpLink(role);
  const router = useRouter();

  const [isMobileD, setIsMobileD] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileD(window.innerWidth < 1100);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const options = [

    { label: 'Для себя', isActive: true, path: '/all-therapists' },
    { label: 'Для ребенка', isActive: true, path: ' /child-therapists' },
    // { label: 'Для семьи и пар', isActive: false, path: '/' },
    // { label: 'Супервизоры', isActive: false, path: '/' }

  ];

  return (
    <Root $isMobile={isMobile} className={className}>
      <WhatsAppButton $isMobile={isMobile}>
        <a href="https://wa.me/+79198707755" target="_blank" rel="noreferrer">
          {/* <a href="https://wa.me/+79263833022" target="_blank" rel="noreferrer"> */}
          <WhatsApp />
        </a>
      </WhatsAppButton>

      {role === 'client' && !isMobileD && (

        <SelectSpecialist options = { options }/>

      )}


      {` `}
      <noscript>
        <br />
      </noscript>

      {!isMobile && (
        <SignInButton
          styleType="outline"
          href={signUpLink}
          query={router.query}
          target="_blank"
          rel="noopener noreferrer"
        >
          Войти
        </SignInButton>
      )}
      {` `}
      <noscript>
        <br />
      </noscript>
      {role === 'therapist' && (
        <StyledButtonLink
          href={API_URL.therapistSignUp}
          query={router.query}
          target="_blank"
          rel="noopener noreferrer"
        >
          Заполнить анкету
        </StyledButtonLink>
      )}
      {role === 'business' && !isMobile && (
        <BusinessButton onClick={() => openModal(UseBusinessModalEnum.Request)}>
          Оставить заявку
        </BusinessButton>
      )}
      {modal}
    </Root>
  );
};

export default Buttons;

const SignInButton = styled(ButtonLink)`
  display: flex !important;
  align-items: center;
  justify-content: center;
  width: ${apx.d(147)};
  height: ${apx.d(46)};
  border-radius: ${apx.d(43)} !important;
  margin-right: ${apx.d(16)};

  @media (max-width: ${BreakpointsEnum.tablet}px) {
    width: ${apx.l(83)};
    height: ${apx.l(30)};
    border-radius: ${apx.l(43)} !important;
    margin-right: ${apx.l(16)};
    font-size: ${apx.l(14)};
    line-height: ${apx.l(17)};
    padding-top: ${apx.l(3)};
  }

  @media (max-width: ${BreakpointsEnum.mb}px) {
    width: ${apx.m(83)};
    height: ${apx.m(30)};
    border-radius: ${apx.m(43)} !important;
    margin-right: ${apx.m(16)};

    font-size: ${apx.m(14)};
    line-height: ${apx.m(17)};
  }
`;

const StyledButtonLink = styled(ButtonLink)`
  padding: ${apx.d(14)} ${apx.d(28)};
  height: ${apx.d(46)};
  margin-right: ${apx.d(18)};
  border-radius: ${apx.d(43)};
  margin-left: ${apx.d(20)};

  @media (max-width: ${BreakpointsEnum.tablet}px) {
    display: none !important;
  }
`;

const BusinessButton = styled(Button)`
  padding: ${apx.d(10)} ${apx.d(40)};
  margin-left: ${apx.d(20)};
  margin-right: ${apx.d(20)};

  @media (max-width: ${BreakpointsEnum.tablet}px) {
    padding: ${apx.l(10)} ${apx.l(20)} !important;
    font-size: ${apx.l(13)} !important;
    margin-left: ${apx.l(20)};
    margin-right: ${apx.l(20)};
  }
`;

const Root = styled.div<{ $isMobile: boolean }>`
  /* ${({ $isMobile }) => {
    if ($isMobile) {
      return css`
        ${SignInButton} {
          width: 100%;
          margin-right: 0;
          margin-bottom: 16px;
          padding: 18px 60px;
          ${TYPOGRAPHY.bodySemibold20};
          border-radius: 17px;
          text-align: center;
        }

        ${StyledButtonLink} {
          padding: 18px 60px;
          ${TYPOGRAPHY.bodySemibold20};
          border-radius: 17px;
          text-align: center;
        }

        ${BusinessButton} {
          padding: 18px 60px;
          ${TYPOGRAPHY.bodySemibold20};
          border-radius: 17px;
        }
      `;
    }
  }} */
`;

const WhatsAppButton = styled.div<{ $isMobile: boolean }>`
  width: ${apx.d(32)};
  height: ${apx.d(32)};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: ${apx.d(39)};
  background-color: transparent;
  border-radius: ${apx.d(40)};

  @media (max-width: ${BreakpointsEnum.tablet}px) {
    display: none;
  }

  svg {
    width: ${apx.d(32)};
    height: ${apx.d(32)};
    path {
      fill: ${COLORS.mint};
    }
  }

  ${({ $isMobile }) => {
    if ($isMobile) {
      return css`
        width: 100%;
        height: ${apx.m(64)};
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: ${apx.m(17)};
        background: none;
        border: ${apx.m(1)} solid ${COLORS.green};
        margin-bottom: ${apx.m(16)};
        margin-right: 0;

        svg {
          width: ${apx.m(25)};
          height: ${apx.m(25)};
        }
      `;
    }
  }}
`;
