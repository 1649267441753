export const cards = [
  {
    title: 'Комфортная экосистема',
    text: 'Будучи специалистами, мы как никто знаем, что нужно в нашем важном и непростом деле, поэтому, помимо причастности к community, вы получите весь арсенал, помогающих в практике средств, интервизии, супервизии, лекции и образовательные программы.',
  },
  {
    title: 'Возможность получить повышение квалификации',
    text: 'Одним из направлений Корпорации добра является свой институт, в котором вы можете получить ДПО или повысить свою квалификацию.',
  },
  {
    title: 'Работа в коллективе опытных профессионалов',
    text: 'И конечно, нас выбирают удивительные клиенты. Мы большой, работающий не первый год проект, заслуживший положительную репутацию. К нам приходят те, с кем действительно испытываешь удовольствие при работе.',
  },
];
