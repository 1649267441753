import { COLORS, TYPOGRAPHY } from '@landing/constants';
import { BreakpointsEnum } from '@landing/types';
import React, { FC } from 'react';
import styled from 'styled-components';
import grass5 from '@landing/assets/images/grass-5.svg';
import grass6 from '@landing/assets/images/grass-6.svg';
import grass7 from '@landing/assets/images/grass-7.svg';
import grass8 from '@landing/assets/images/grass-8.svg';
import { useClientSize, useToggle } from '@landing/hooks';
import Image from 'next/image';
import { Modal } from '@landing/ui/components';

type BannerThoughtfulSuggestionProps = {
  isWhite: boolean;
};

export const BannerThoughtfulSuggestion: FC<
  BannerThoughtfulSuggestionProps
> = ({ isWhite, ...props }) => {
  const { state: isOpened, toggle: toggleOpen } = useToggle();

  const { getIsBreakpoint } = useClientSize();

  const isMobile = getIsBreakpoint('md');

  return (
    <Root $isWhite={isWhite} {...props}>
      <Content>
        <Title>Заботливое предложение</Title>
        <Heading>«Вернем деньги»</Heading>
        <Subheading>если не устроит качество</Subheading>
      </Content>
      {isMobile ? (
        <DecorationFlowerMobile>
          <Image
            width={800}
            height={600}
            src={isWhite ? grass6.src : grass8.src}
            alt="Цветок на фоне"
          />
        </DecorationFlowerMobile>
      ) : (
        <DecorationFlower>
          <Image
            width={800}
            height={600}
            src={isWhite ? grass5.src : grass7.src}
            alt="Цветок на фоне"
          />
        </DecorationFlower>
      )}
      <LinkMore onClick={toggleOpen}>Подробнее о предложении</LinkMore>

      <StyledModal
        isOpen={isOpened}
        onClose={toggleOpen}
        heading="Правила предложения «Вернем деньги»"
      >
        <ContentModal>
          <Text>
            Мы в Поговорим.online уверены в каждом специалисте нашей команды 🚀
          </Text>
          <Text>
            Самое важное для нас - довольный и счастливый клиент, поэтому, если
            что-то пошло не так, то мы гарантируем возврат 100% стоимости
            консультации, которая вас не устроила.
          </Text>
          <Text>Мы вам доверяем и рассматриваем любые причины ✨</Text>
          <Text>
            Для участия в акции важно соблюдение каждого из следующих условий:
          </Text>
          <ul>
            <li>
              Напиши нам о своем случае не позднее, чем через 3 часа после
              окончания сессии на{' '}
              <a href="mailto:prosto@pogovorim.online">
                prosto@pogovorim.online
              </a>
            </li>
            <li>
              Письмо должно содержать ответ на вопрос «Чем вас не устроило
              качество консультации?»
            </li>
            <li>
              Не забудьте указать ваши контактные данные: номер телефона или
              электронную почту, на которую зарегистрирован аккаунт
            </li>
          </ul>
          <Text>
            Предложение действует только для первой консультации на платформе
            Поговорим.online 🌿
          </Text>
          <Text>
            Мы вернем деньги в течение 10 часов.
            <br />
            Спасибо, что помогаете нам стать лучше!
          </Text>
          ❤️
        </ContentModal>
      </StyledModal>
    </Root>
  );
};

const Root = styled.div<{ $isWhite: boolean }>`
  display: flex;
  height: 152px;
  background-color: ${({ $isWhite }) =>
    $isWhite ? COLORS.white : COLORS.primary200};
  border-radius: 16px;
  padding: 28px 40px;
  justify-content: space-between;
  align-items: flex-end;
  position: relative;
  overflow: hidden;
  max-width: 1140px;
  width: 100%;
  z-index: 100;

  @media (max-width: ${BreakpointsEnum.md}px) {
    height: 148px;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px 0 22px 16px;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const Title = styled.p`
  ${TYPOGRAPHY.bodySemibold20}
  color: ${COLORS.dark600};

  @media (max-width: ${BreakpointsEnum.md}px) {
    ${TYPOGRAPHY.bodySemibold16}
  }
`;

const Heading = styled.p`
  color: ${COLORS.secondary600};
  ${TYPOGRAPHY.headline2Semibold36};
  margin-bottom: 8px;

  @media (max-width: ${BreakpointsEnum.md}px) {
    ${TYPOGRAPHY.bodySemiBold24};
    margin-bottom: 4px;
  }
`;

const Subheading = styled.p`
  color: ${COLORS.primary500};
  font-style: italic;
  font-weight: 500;
  line-height: 21px;

  @media (max-width: ${BreakpointsEnum.md}px) {
    font-size: 14px;
  }
`;

const LinkMore = styled.p`
  color: ${COLORS.primary500};
  font-size: 18px;
  line-height: 21px;
  font-style: italic;
  text-decoration-line: underline;
  cursor: pointer;
  z-index: 101;

  @media (max-width: ${BreakpointsEnum.md}px) {
    font-size: 12px;
  }
`;

const DecorationFlower = styled.div`
  width: 400px;
  height: 700px;
  position: absolute;
  top: -76px;
  left: 46%;
`;

const DecorationFlowerMobile = styled.div`
  width: 200px;
  height: 300px;
  position: absolute;
  top: -00px;
  right: -20px;
`;

const StyledModal = styled(Modal)`
  z-index: 1000;
  @media (max-width: ${BreakpointsEnum.sm}px) {
    && {
      max-width: 100%;
    }
  }
`;

const ContentModal = styled.div`
  margin-top: 24px;
  ${TYPOGRAPHY.bodyRegular16};

  ul {
    margin-bottom: 16px;
  }

  li {
    list-style: unset;
    padding: unset;
    padding: 4px;
    margin: 0 10px 0 16px;
  }
`;

const Text = styled.p`
  margin-bottom: 16px;
`;
