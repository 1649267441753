import { FC } from 'react';
import styled from 'styled-components';
import Container from '../Container';
import { COLORS, TYPOGRAPHY } from '@landing/constants';
import Partners from '../../assets/svg/partners.svg';
import AppImage from '../../assets/images/appNew.jpg';
import Image from 'next/image';
import { StatsBox } from './components';
import Partner1 from '../../assets/images/logos/logo1.svg';
import Partner2 from '../../assets/images/logos/logo2.svg';
import Partner3 from '../../assets/images/logos/logo3.svg';
import Partner4 from '../../assets/images/logos/logo4.svg';
import { BreakpointsEnum } from '@landing/types';
import apx from '@landing/constants/adaptivepx';

const PartnersSection: FC = () => {
  return (
    <Root id="partners">
      <WrapperContainer>
        <PartnersTopWrapper>
          <Image
            width={424}
            height={70}
            src={Partners}
            alt="Smart Med + Поговорим.Online"
          />
          <TitleDescr>
            Вместе со Smartmed наши психологи консультируют клиентов клиник
            МЕДСИ
          </TitleDescr>
        </PartnersTopWrapper>
        <PartnersMiddleWrapper>
          <Image src={AppImage} alt="app" width={933} height={735} />
          <StatsBoxWrRapper>
            <StatsBox title="145 клиник" subtitle="«МЕДСИ»" />
            <StatsBox title=">10 миллионов" subtitle="посещений клиник МЕДСИ" />
            <StatsBox title=">15 000" subtitle="сотрудников" />
          </StatsBoxWrRapper>
        </PartnersMiddleWrapper>
        <PartnersSubtitle>Наши партнёры</PartnersSubtitle>
        <PartnersBottomWrapper>
          <Image src={Partner1} alt="Askona" />
          <Image src={Partner2} alt="Colife" />
          <Image src={Partner3} alt="Нетотлогия" />
          <Image src={Partner4} alt="Liber Lingva" />
        </PartnersBottomWrapper>
      </WrapperContainer>
    </Root>
  );
};

export default PartnersSection;

const Root = styled.section`
  padding-bottom: 126px;
  background: ${COLORS.white};

  @media (max-width: ${BreakpointsEnum.s}px) {
    padding-bottom: 45px;
    padding-left: 0;
  }
`;

const WrapperContainer = styled(Container)`
  @media (max-width: ${BreakpointsEnum.tablet}px) {
    padding: 0 ${apx.l(61)};
  }

  @media (max-width: ${BreakpointsEnum.mb}px) {
    padding: 0;
  }
`;

// const PartnersTitle = styled.h2`
//
//   ${TYPOGRAPHY.heading5Semibold48}
//   font-weight: 700;
//   line-height: ${ apx.d( 59 ) };
//   margin-bottom: ${ apx.d( 43 ) };
//   text-align: center;
//   color: ${COLORS.nativeBlack};
//
//   @media (max-width: ${BreakpointsEnum.tablet}px) {
//
//     font-size: ${ apx.l( 36 ) } !important;
//     line-height: ${ apx.l( 40 ) };
//     margin-bottom: ${ apx.l( 31 ) } !important;
//
//   }
//
//
//   @media (max-width: ${BreakpointsEnum.s}px) {
//
//     font-size: ${ apx.m( 30 ) } !important;
//     margin-bottom: ${ apx.m( 27 ) } !important;
//     line-height: ${ apx.m( 37 ) } !important;
//
//   }
// `;

const PartnersTopWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    font-size: ${apx.d(20)};
    line-height: 165%;
  }

  img {
    width: ${apx.d(506)};
    height: ${apx.d(84)};
  }

  @media (max-width: ${BreakpointsEnum.tablet}px) {
    p {
      font-size: ${apx.l(15)};
    }

    img {
      width: ${apx.l(379)} !important;
      height: ${apx.l(63)} !important;
    }
  }

  @media (max-width: ${BreakpointsEnum.mb}px) {
    p {
      margin: ${apx.m(25)} 0 ${apx.m(22)} 0;
      font-size: ${apx.m(13)};
      max-width: ${apx.m(335)};
      line-height: 150%;
    }

    img {
      width: ${apx.m(303)} !important;
      height: ${apx.m(52)} !important;
    }
  }
`;

const TitleDescr = styled.p`
  text-align: center;
  max-width: ${apx.d(891)};
  margin: ${apx.d(37)} 0 ${apx.d(64)} 0;
  line-height: ${apx.d(33)};
  ${TYPOGRAPHY.bodyMedium20}
  color: ${COLORS.nativeBlack};

  @media (max-width: ${BreakpointsEnum.tablet}px) {
    font-size: ${apx.l(15)} !important;
    max-width: ${apx.l(668)};

    margin: ${apx.l(27)} 0 ${apx.l(17)} 0;
    line-height: 165%;
  }

  @media (max-width: ${BreakpointsEnum.mb}px) {
    font-size: ${apx.m(13)} !important;
    max-width: ${apx.m(335)};

    margin: ${apx.m(25)} 0 ${apx.m(22)} 0;
    line-height: 150%;
  }
`;

const PartnersMiddleWrapper = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  justify-content: center !important;
  margin-bottom: ${apx.d(50)};

  img {
    width: ${apx.d(933)};
    height: ${apx.d(735)};
  }

  @media (max-width: ${BreakpointsEnum.tablet}px) {
    img {
      width: 100% !important;
      height: auto !important;
    }
  }

  @media (max-width: ${BreakpointsEnum.mb}px) {
    img {
      object-fit: cover;
      min-width: ${apx.m(451)} !important;
      height: ${apx.m(400)} !important;
      margin: 0 auto;
      left: -${apx.m(33)} !important;
    }

    span {
      width: 100%;
    }
  }

  /* 
  @media (max-width: ${BreakpointsEnum.s}px) {
    width: 100%;

    span {
      display: block;
      width: 469px !important;
    }

    img {

      width: 470px !important;
      height: 429px !important;
      min-width: 100% !important;
      object-fit: cover;

    }
 
  } */
`;

const StatsBoxWrRapper = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  column-gap: ${apx.d(37)};

  right: 0;
  left: 0;
  bottom: 0;

  @media (max-width: ${BreakpointsEnum.tablet}px) {
    bottom: ${apx.l(165)};
    display: flex;
    justify-content: center;
    gap: ${apx.l(20)};
    bottom: ${apx.l(18)};
    left: 0;
    margin-left: 0px;

    right: 0;
  }

  @media (max-width: ${BreakpointsEnum.mb}px) {
    bottom: -${apx.m(14)};
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding-left: ${apx.m(24)};
    padding-right: ${apx.m(21)};

    div {
      width: 100%;
      height: ${apx.m(68)};
    }

    div:nth-child(1) {
    }

    div:nth-child(2) {
      grid-row-end: 2;
      grid-row-start: 2;
      grid-column-start: 1;
      grid-column-end: 3;
      width: 100%;
    }

    div:nth-child(3) {
    }
  }
`;

const PartnersSubtitle = styled.p`
  color: ${COLORS.nativeBlack};
  ${TYPOGRAPHY.bodyBold36}
  text-align: center;
  margin: ${apx.d(45)} 0 ${apx.d(60)} 0;
  font-size: ${apx.d(32)};
  line-height: ${apx.d(40)};

  @media (max-width: ${BreakpointsEnum.tablet}px) {
    font-size: ${apx.l(24)};
    line-height: ${apx.l(30)};
    margin-bottom: ${apx.l(45)};
    margin-top: ${apx.l(43)};
  }

  @media (max-width: ${BreakpointsEnum.mb}px) {
    margin-bottom: ${apx.m(24)};
    margin-top: ${apx.m(60)};
    font-size: ${apx.m(24)};
    line-height: ${apx.m(30)};
  }
`;

const PartnersBottomWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: ${apx.d(142)};

  span:nth-child(1) {
    width: ${apx.d(180)} !important;
    height: ${apx.d(45)} !important;
  }

  span:nth-child(2) {
    width: ${apx.d(117)} !important;
    height: ${apx.d(42)} !important;
  }

  span:nth-child(3) {
    width: ${apx.d(189)} !important;
    height: ${apx.d(20)} !important;
  }

  span:nth-child(4) {
    width: ${apx.d(110)} !important;
    height: ${apx.d(68)} !important;
  }

  @media (max-width: ${BreakpointsEnum.tablet}px) {
    display: flex;
    gap: ${apx.l(55)};
    width: 100%;
    padding: 0 ${apx.l(30)};

    span:nth-child(1) {
      width: ${apx.l(135)} !important;
      height: ${apx.l(34)} !important;
    }

    span:nth-child(2) {
      width: ${apx.l(88)} !important;
      height: ${apx.l(47)} !important;
    }

    span:nth-child(3) {
      width: ${apx.l(142)} !important;
      height: ${apx.l(16)} !important;
    }

    span:nth-child(4) {
      width: ${apx.l(83)} !important;
      height: ${apx.l(51)} !important;
    }
  }

  @media (max-width: ${BreakpointsEnum.mb}px) {
    display: grid !important;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    justify-items: center;
    row-gap: ${apx.m(56)};
    column-gap: ${apx.m(28)};

    span:nth-child(1) {
      width: ${apx.m(108)} !important;
      height: ${apx.m(26)} !important;
    }

    span:nth-child(2) {
      width: ${apx.m(77)} !important;
      height: ${apx.m(42)} !important;
    }

    span:nth-child(3) {
      width: ${apx.m(127)} !important;
      height: ${apx.m(13)} !important;
      grid-column-start: 1;
      grid-row-start: 2;
      grid-column-start: 2;
    }

    span:nth-child(4) {
      width: ${apx.m(75)} !important;
      height: ${apx.m(44)} !important;
      grid-row-start: 1;
      grid-column-start: 2;
    }
  }
`;
