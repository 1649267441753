import { COLORS, TYPOGRAPHY } from '@landing/constants';
import styled from 'styled-components';
import Container from '../Container';
import { BreakpointsEnum } from '@landing/types';
import apx from '@landing/constants/adaptivepx';

export const Root = styled.section`
  width: 100%;
  background: ${COLORS.white};
  overflow: hidden;
  border-radius: 0;

  @media (max-width: ${BreakpointsEnum.sm}px) {
    margin-top: 0;
  }
`;

export const WhyText = styled.section<{ mobile?: boolean }>`
  display: block !important;
  margin-top: 0 !important;
  position: relative !important;

  &::after {
    content: '';
    position: absolute;
    display: ${({ mobile }) => (mobile ? 'block' : 'none')};

    height: ${apx.m(20)};
    width: 100%;

    bottom: 0;

    background: rgb(255, 255, 255);
    background: linear-gradient(
      0,
      rgba(255, 255, 255, 1) 20%,
      rgba(0, 0, 0, 0) 100%
    );
  }

  div::after {
    display: none;
  }
  p:not(:last-child) {
    margin-bottom: ${apx.d(34)} !important;
  }

  a {
    color: ${COLORS.mint} !important;
    font-weight: 700;
    text-decoration: underline !important;
  }

  a {
    color: ${COLORS.mint} !important;
    font-weight: 700;
    text-decoration: underline !important;
  }

  @media (max-width: ${BreakpointsEnum.tablet}px) {
    p:not(:last-child) {
      margin-bottom: ${apx.l(10)} !important;
    }
  }

  @media (max-width: ${BreakpointsEnum.mb}px) {
    p:not(:last-child) {
      margin-bottom: ${apx.m(10)} !important;
    }
  }
`;

export const StyledContainer = styled(Container)``;

export const SectionTitle = styled.h4`
  font-size: ${apx.d(48)};
  line-height: ${apx.d(59)};
  font-weight: 700;
  text-align: center !important;
  margin: 0 auto;

  display: none;

  @media (max-width: ${BreakpointsEnum.mb}px) {
    display: block;
    font-size: ${apx.m(29)};
    line-height: ${apx.m(37)};
    margin-top: ${apx.m(41)};
  }
`;

export const WhyContentWrapper = styled.div`
  width: 100%;
  display: flex;
  column-gap: ${apx.d(73)};
  margin-bottom: ${apx.d(97)};
  align-items: center;

  @media (max-width: ${BreakpointsEnum.tablet}px) {
    column-gap: ${apx.l(25)};
    margin-bottom: ${apx.l(90)};
  }

  @media (max-width: ${BreakpointsEnum.mb}px) {
    gap: ${apx.m(15)};
    align-items: flex-start;
    display: flex;
    flex-direction: column;
  }
`;

export const ImageBox = styled.div`
  position: relative;
  background-size: 100%;
  border-radius: ${apx.d(40)};
  width: ${apx.d(550)};
  height: ${apx.d(688)};
  padding: ${apx.d(46)} ${apx.d(34)};
  display: flex;
  align-items: flex-end;
  background-repeat: no-repeat;

  button {
    position: absolute;

    width: ${apx.d(63)};
    height: ${apx.d(69)};
    margin: auto;
    min-width: auto;

    left: 0;
    top: 0;
    right: -${apx.d(30)};
    bottom: 0;

    img,
    span {
      width: 100% !important;
      height: 100% !important;
    }
  }

  @media (max-width: ${BreakpointsEnum.tablet}px) {
    width: ${apx.l(308)};

    height: ${apx.l(387)};
    padding-left: ${apx.l(30)};
    padding-bottom: ${apx.l(30)};

    button {
      width: ${apx.l(49)};
      height: ${apx.l(49)};

      right: -${apx.l(30)};
    }
  }

  @media (max-width: ${BreakpointsEnum.mb}px) {
    margin-top: 0;
    margin-bottom: ${apx.m(16)};
    background-position: top;
    height: ${apx.m(370)};
    width: 100%;
    border-radius: ${apx.m(22)};

    padding-left: ${apx.m(25)};
    padding-bottom: ${apx.m(18)};

    button {
      width: ${apx.m(70)};
      height: ${apx.m(70)};
    }
  }
`;

export const ImageInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ImageTitle = styled.h4`
  ${TYPOGRAPHY.bodyBold22};
  color: ${COLORS.white};
  margin-bottom: ${apx.d(4)};
  width: ${apx.d(164)};
  text-shadow: 0px 1px 2px rgba(41, 13, 13, 0.5);

  @media (max-width: ${BreakpointsEnum.tablet}px) {
    width: 100%;
    font-size: ${apx.l(12)} !important;
    font-weight: 700 !important;
    line-height: ${apx.l(16)};
    margin: 0;
  }

  @media (max-width: ${BreakpointsEnum.mb}px) {
    font-size: ${apx.m(16)} !important;
    line-height: ${apx.m(19)};
  }
`;

export const ImageDescr = styled.p`
  max-width: ${apx.d(196)};
  ${TYPOGRAPHY.bodyMedium14};
  line-height: ${apx.d(17)};
  color: ${COLORS.white};
  text-shadow: 0px 1px 2px rgba(41, 13, 13, 0.5);

  @media (max-width: ${BreakpointsEnum.tablet}px) {
    font-size: ${apx.l(7)};
    line-height: ${apx.l(10)};
    max-width: ${apx.l(110)};
  }

  @media (max-width: ${BreakpointsEnum.mb}px) {
    font-size: ${apx.m(12)};
    line-height: ${apx.m(15)};
    max-width: ${apx.m(170)};
  }
`;

export const InfoBox = styled.div<{ isActive: boolean }>`
  position: relative;

  div {
    display: none;
    cursor: pointer;
    margin-top: ${apx.d(39)};
  }

  div:first-child {
    margin: 0 !important;
  }

  @media (max-width: ${BreakpointsEnum.tablet}px) {
    div {
      display: flex;
      column-gap: ${apx.l(2)};
      align-items: center;
      font-size: ${apx.l(14)};
      font-weight: 600;
      line-height: ${apx.l(17)};
      bottom: -${apx.l(27)};
      margin-top: ${apx.l(30)} !important;

      &:after {
        line-height: 0;
        content: '. . . . . . . . . . . . . . . . . .';
        width: 100%;
        height: ${apx.l(1)};
        position: absolute;
        bottom: 0;
        left: 0;
      }
    }

    div:first-child {
      margin: 0 !important;
    }
  }

  @media (max-width: ${BreakpointsEnum.mb}px) {
    height: auto;
    padding: 0;

    div {
      column-gap: ${apx.m(2)};
      font-size: ${apx.m(14)};
      line-height: ${apx.m(17)};
      margin-top: ${apx.m(15)} !important;

      &:after {
        height: ${apx.m(1)};
      }
    }

    div:first-child {
      margin: 0 !important;
    }
  }
`;

export const WhyTitle = styled.h2`
  margin-bottom: ${apx.d(25)};
  ${TYPOGRAPHY.heading5Semibold48};
  font-weight: 700;
  line-height: ${apx.d(59)};
  color: ${COLORS.nativeBlack};
  width: ${apx.d(531)};

  @media (max-width: ${BreakpointsEnum.tablet}px) {
    font-size: ${apx.l(36)} !important;
    line-height: ${apx.l(40)};
    width: 100%;
  }

  @media (max-width: ${BreakpointsEnum.mb}px) {
    width: 100%;
    ${TYPOGRAPHY.headline2Semibold36};
    font-weight: 700;
    margin-bottom: ${apx.m(16)};

    display: none !important;

    font-size: ${apx.m(25)};
    top: -${apx.m(436)};
  }
`;

export const WhyDescr = styled.p<{ mobile?: boolean }>`
  max-width: ${apx.d(617)};
  ${TYPOGRAPHY.bodyMedium18};
  font-weight: 400;
  line-height: ${apx.d(30)};
  color: ${COLORS.nativeBlack};
  position: relative;

  @media (max-width: ${BreakpointsEnum.tablet}px) {
    font-size: ${apx.l(14)};
    line-height: ${apx.l(22)};
    max-width: ${apx.l(336)};
  }

  @media (max-width: ${BreakpointsEnum.mb}px) {
    font-size: ${apx.m(13)};
    line-height: ${apx.m(18)};
    max-width: 100%;

    max-width: none;
    width: 100%;
    position: relative;
  }
`;
