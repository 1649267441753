import { FC } from 'react';
import styled from 'styled-components';

interface Props {
  src: string;
  alt: string;
}

const SlideImage: FC<Props> = ({ src, alt }) => {
  return <Img src={src} key={alt} />;
};

export default SlideImage;

const Img = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
`;
