import styled from 'styled-components';
import Image from 'next/image';
import { COLORS, TYPOGRAPHY } from '@landing/constants';
import { BreakpointsEnum } from '@landing/types';
import { FC } from 'react';
import Container from '../Container';
import { cards } from './data';
import grass from '@landing/assets/images/grass-3.svg';
import beautifulFlower from '@landing/assets/images/beautiful-flower.svg';
import apx from '@landing/constants/adaptivepx';

const OurValuesSection: FC = () => {
  return (
    <Root>
      <StyledContainer>
        <Title>Наши ценности</Title>
        <TextContent>
          {cards.map(({ title, text }, index) => (
            <Card key={index}>
              <CardTittle>{title}</CardTittle>
              <CardText>{text}</CardText>
            </Card>
          ))}
        </TextContent>
      </StyledContainer>
      <BgGrass>
        <Image
          width={195}
          height={277}
          src={grass.src}
          layout="responsive"
          alt="Трава на фоне"
        />
      </BgGrass>
      <BgFlower>
        <Image
          width={407}
          height={362}
          src={beautifulFlower.src}
          layout="responsive"
          alt="Цветок на фоне"
        />
      </BgFlower>
    </Root>
  );
};

export default OurValuesSection;

const Root = styled.section`
  width: 100%;
  background: ${COLORS.primary200};
  position: relative;
  overflow: hidden;
`;

const StyledContainer = styled(Container)`
  padding-top: ${apx.d(80)};
  padding-bottom: ${apx.d(173)};

  @media (max-width: ${BreakpointsEnum.tablet}px) {
    padding-top: ${apx.l(40)};
    padding-bottom: ${apx.l(242)};
  }

  @media (max-width: ${BreakpointsEnum.mb}px) {
    padding-top: ${apx.m(40)};
    padding-bottom: ${apx.m(242)};
  }
`;

const Title = styled.h2`
  margin-bottom: ${apx.d(80)};
  ${TYPOGRAPHY.heading2SemiBold64};
  color: ${COLORS.dark800};

  @media (max-width: ${BreakpointsEnum.tablet}px) {
    margin-bottom: ${apx.l(57)};
    font-size: ${apx.l(64)};
    line-height: 130%;
  }

  @media (max-width: ${BreakpointsEnum.mb}px) {
    margin-bottom: ${apx.m(57)};
    ${TYPOGRAPHY.headline2Semibold36};
    font-size: ${apx.m(36)};
    line-height: ${apx.m(36)};
  }
`;

const TextContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
`;

const Card = styled.div`
  width: 100%;
  max-width: ${apx.d(522)};
  padding-bottom: ${apx.d(30)};
  position: relative;

  &:after {
    content: '';
    width: 100%;
    max-width: ${apx.d(211)};
    height: ${apx.d(2)};
    background: ${COLORS.primary400};
    display: block;
    position: absolute;
    left: 0;
    bottom: 0;
  }

  &:first-of-type {
    margin-right: ${apx.d(58)};
  }

  &:nth-of-type(2) {
    margin-top: ${apx.d(127)};
  }

  @media (max-width: ${BreakpointsEnum.tablet}px) {
    max-width: ${apx.l(522)};
    padding-bottom: ${apx.l(30)};

    &:not(:last-of-type) {
      margin-bottom: ${apx.l(24)};
    }

    &:after {
      max-width: ${apx.l(211)};
      height: ${apx.l(2)};
    }

    &:nth-of-type(2) {
      margin-top: ${apx.l(127)};
    }

    &:first-of-type {
      margin-right: 0;
    }

    &:nth-of-type(2) {
      margin-top: 0;
    }
  }

  @media (max-width: ${BreakpointsEnum.mb}px) {
    max-width: ${apx.m(522)};
    padding-bottom: ${apx.m(30)};

    &:not(:last-of-type) {
      margin-bottom: ${apx.m(24)};
    }

    &:after {
      max-width: ${apx.m(211)};
      height: ${apx.m(2)};
    }

    &:nth-of-type(2) {
      margin-top: ${apx.m(127)};
    }

    &:first-of-type {
      margin-right: 0;
    }

    &:nth-of-type(2) {
      margin-top: 0;
    }
  }
`;

const CardTittle = styled.h3`
  width: 100%;
  max-width: ${apx.d(459)};
  margin-bottom: ${apx.d(16)};
  ${TYPOGRAPHY.headline2Semibold36};
  color: ${COLORS.primary500};

  @media (max-width: ${BreakpointsEnum.tablet}px) {
    margin-bottom: ${apx.l(16)};
    max-width: ${apx.l(459)};

    font-size: ${apx.l(36)};
    line-height: ${apx.l(36)};
  }

  @media (max-width: ${BreakpointsEnum.mb}px) {
    max-width: 100%;
    margin-bottom: ${apx.m(16)};

    font-size: ${apx.m(24)};
    line-height: ${apx.m(31)};
  }
`;

const CardText = styled.p`
  ${TYPOGRAPHY.bodyRegular16};
  color: ${COLORS.dark700};

  @media (max-width: ${BreakpointsEnum.tablet}px) {
    font-size: ${apx.l(16)};
    line-height: ${apx.l(21)};
  }

  @media (max-width: ${BreakpointsEnum.mb}px) {
    font-size: ${apx.m(16)};
    line-height: ${apx.m(21)};
  }
`;

const BgGrass = styled.div`
  width: ${apx.d(195)};
  height: ${apx.d(277)};
  position: absolute;
  top: 0;
  right: 0;

  img {
    display: block;
  }

  @media (max-width: ${BreakpointsEnum.tablet}px) {
    width: ${apx.l(175)};
    height: ${apx.l(107)};
  }

  @media (max-width: ${BreakpointsEnum.mb}px) {
    width: ${apx.m(75)};
    height: ${apx.m(107)};
  }
`;

const BgFlower = styled.div`
  width: ${apx.d(407)};
  height: ${apx.d(362)};
  position: absolute;
  bottom: 0;
  right: 0;

  img {
    display: block;
  }

  @media (max-width: ${BreakpointsEnum.tablet}px) {
    width: ${apx.l(222)};
    height: ${apx.l(197)};
    right: -${apx.l(28)};
  }

  @media (max-width: ${BreakpointsEnum.mb}px) {
    width: ${apx.m(222)};
    height: ${apx.m(197)};
    right: -${apx.m(28)};
  }
`;
