import { useMemo } from 'react';

export const useLockScroll = () => {
  return useMemo(
    () => ({
      lockScroll: () => {
        document.body.style.overflow = 'hidden';
      },
      unlockScroll: () => {
        document.body.style.overflow = '';
      },
    }),
    [],
  );
};
