import styled from 'styled-components';
import { ButtonLink } from '@landing/ui/components';
import { BreakpointsEnum } from '@landing/types';
import apx from '@landing/constants/adaptivepx';
import { TYPOGRAPHY } from '@landing/constants';


interface DropdownItemProps {

  isActive: boolean;

}

export const DropdownContainer = styled.div`

  position: relative;

`;

export const DropdownButton = styled(ButtonLink)`
  position: relative;
  z-index: 20;

  height: ${apx.d(46)};
  margin-right: ${apx.d(18)};
  margin-left: ${apx.d(20)};

  border-radius: ${apx.d(43)};
  padding: ${apx.d(14)} ${apx.d(28)};
  white-space: nowrap;

  @media (max-width: ${BreakpointsEnum.tablet}px) {

    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
    padding: ${apx.l(12)} ${apx.l(33)};
    ${TYPOGRAPHY.footnoteMedium13};
    font-weight: 600;

    max-width: ${apx.l(170)};
    width: 100%;
    line-height: ${apx.l(10)};
    border-radius: ${apx.l(51)};
    height: ${apx.l(40)};
    font-size: ${apx.l(12)};

  }

  @media (max-width: ${BreakpointsEnum.s}px) {

    padding: ${apx.m(20)} !important;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
    padding: 0;
    ${TYPOGRAPHY.footnoteMedium13};
    font-weight: 600;

    max-width: ${apx.m(275)};
    width: 100%;
    line-height: ${apx.m(20)};
    border-radius: ${apx.l(51)};
    height: ${apx.m(50)};
    font-size: ${apx.m(16)};

  }

`;

export const DropdownList = styled.ul`

  position: absolute;
  top: ${apx.d(45.4)};
  left: ${apx.d(48)};
  z-index: 15;

  width: ${apx.d(196)};
  background: #FFFFFF;
  list-style: none;

  border: 1px solid #3DAE9F;
  border-radius: 0 ${apx.d(20)} ${apx.d(20)} ${apx.d(20)};
  padding: ${apx.d(10)} ${apx.d(20)};
  margin: 0;

  @media (max-width: ${BreakpointsEnum.tablet}px) {

    border-radius: 0 0 ${apx.d(20)} ${apx.d(20)};
    width: ${apx.l(70)};
    left: ${apx.l(42)};
    padding: ${apx.l(10)} ${apx.l(20)};

  }

  @media (max-width: ${BreakpointsEnum.s}px) {

    border-radius: 0 0 ${apx.m(20)} ${apx.m(20)};
    width: ${apx.m(190)};
    top: ${apx.m(30)};
    left: ${apx.m(17)};
    padding: ${apx.m(10)} ${apx.m(20)};

  }

`;

export const DropdownItem = styled.li<DropdownItemProps>`

  padding-top: ${apx.d(10)};
  padding-bottom: ${apx.d(10)};
  border-bottom: 1px solid rgba(61, 174, 159, 0.5);

  cursor: pointer;
  font-size: ${apx.d(16)};
  font-weight: 500;
  color: ${( props ) => (props.isActive ? '#141414' : '#A0A0A0')};

  text-align: left;

  &:hover {

    color: #3DAE9F;
    transition: color 0.3s ease;

  }

  &:last-child {

    border-bottom: none;

  }

  @media (max-width: ${BreakpointsEnum.tablet}px) {

    font-size: ${apx.l(12)};
    padding-top: ${apx.l(10)};
    padding-bottom: ${apx.l(10)};

  }

  @media (max-width: ${BreakpointsEnum.s}px) {

    font-size: ${apx.m(16)};
    padding-top: ${apx.m(10)};
    padding-bottom: ${apx.m(10)};

  }

`;
