import {
  createContext,
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  useContext,
  useMemo,
  useState,
} from 'react';
import styled, { css } from 'styled-components';
import { COLORS, TYPOGRAPHY } from '@landing/constants';
import { Cross } from '@landing/ui/iconComponents';
import { BreakpointsEnum } from '@landing/types';
import apx from '@landing/constants/adaptivepx';

type AccordionContext = {
  activeAccordion: string | undefined;
  setActiveAccordion: Dispatch<SetStateAction<string | undefined>>;
};

const AccordionContext = createContext<AccordionContext | null>(null);
AccordionContext.displayName = 'AccordionContext';

interface AccordionProviderProps {
  children?: ReactNode | ReactNode[];
}

const AccordionProvider: FC<AccordionProviderProps> = ({ children }) => {
  const [activeAccordion, setActiveAccordion] = useState<string | undefined>(
    '1',
  );

  const value = useMemo(
    () => ({ activeAccordion, setActiveAccordion }),
    [activeAccordion],
  );

  return (
    <AccordionContext.Provider value={value}>
      {children}
    </AccordionContext.Provider>
  );
};

interface AccordionProps {
  id: string;
  title: string;
  textContent: ReactNode;
  className?: string;
}

const AccordionItem: FC<AccordionProps> = ({
  id,
  title,
  textContent,
  className,
}) => {
  const { activeAccordion, setActiveAccordion } = useContext(AccordionContext)!;

  const isActive = useMemo(() => activeAccordion === id, [id, activeAccordion]);

  return (
    <Root
      className={className}
      $isActive={isActive}
      onClick={() =>
        isActive ? setActiveAccordion(undefined) : setActiveAccordion(id)
      }
    >
      <TopContent>
        <Title isActive={isActive}>{title}</Title>
        <IconWrap isActive={isActive}>
          <Cross color={isActive ? COLORS.white : COLORS.dark800} />
        </IconWrap>
      </TopContent>
      <TextContent isActive={isActive}>{textContent}</TextContent>
    </Root>
  );
};

export default { Provider: AccordionProvider, Item: AccordionItem };

const TopContent = styled.div`
  padding: ${apx.d(30)} ${apx.d(40)};
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: ${BreakpointsEnum.tablet}px) {
    padding: ${apx.l(22)} ${apx.l(30)} ${apx.l(22)} ${apx.l(30)};
    gap: ${apx.l(12)};
  }

  @media (max-width: ${BreakpointsEnum.mb}px) {
    padding: ${apx.m(25)};
    gap: ${apx.m(16)};
  }
`;

const Title = styled.h4<{ isActive: boolean }>`
  width: 88%;
  ${TYPOGRAPHY.headline3Bold24};
  color: ${(props) => (props.isActive ? COLORS.white : COLORS.dark800)};

  @media (max-width: ${BreakpointsEnum.tablet}px) {
    line-height: ${apx.l(22)} !important;
    font-size: ${apx.l(18)} !important;
  }

  @media (max-width: ${BreakpointsEnum.mb}px) {
    line-height: ${apx.m(22)} !important;
    font-size: ${apx.m(18)} !important;
  }
`;

const TextContent = styled.div<{ isActive: boolean }>`
  ${TYPOGRAPHY.bodyMedium18};
  color: ${COLORS.dark600};
  transition: all 0.3s;

  ul,
  ol {
    padding-left: ${apx.d(26)};
  }

  ol > li {
    list-style-type: decimal;
  }

  ul > li {
    list-style-type: disc;
  }

  a {
    text-decoration-line: underline;
  }

  opacity: ${(props) => (props.isActive ? 1 : 0)} !important;
  height: ${(props) => (props.isActive ? 'auto' : 0)} !important;
  padding: ${(props) =>
    props.isActive ? `0  ${apx.d(50)}  ${apx.d(30)}  ${apx.d(40)}` : 0};

  @media (max-width: ${BreakpointsEnum.tablet}px) {
    font-size: ${apx.l(14)} !important;
    line-height: ${apx.l(18)} !important;
    padding: ${(props) =>
      props.isActive ? `0  ${apx.l(20)}  ${apx.l(20)}  ${apx.l(30)}` : 0};
  }

  @media (max-width: ${BreakpointsEnum.mb}px) {
    font-size: ${apx.m(13)} !important;
    line-height: 165% !important;
    padding: ${(props) =>
      props.isActive
        ? `${apx.m(10)}  ${apx.m(44)}  ${apx.m(25)}  ${apx.m(25)}`
        : 0} !important;
  }
`;

const IconWrap = styled.div<{ isActive: boolean }>`
  width: ${apx.d(40)};
  height: ${apx.d(40)};
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 100%;
    height: 100%;
  }

  svg {
    transition: transform 0.3s;
    transform: ${({ isActive }) => (isActive ? 'rotate(-225deg)' : '')};
  }

  @media (max-width: ${BreakpointsEnum.tablet}px) {
    width: ${apx.l(30)};
    height: ${apx.l(30)};
  }

  @media (max-width: ${BreakpointsEnum.mb}px) {
    width: ${apx.m(30)};
    height: ${apx.m(30)};
  }
`;

const Root = styled.div<{ $isActive: boolean }>`
  width: 100%;
  height: auto;
  border: ${apx.d(2)} solid ${COLORS.mint};
  border-radius: ${apx.d(8)};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
  overflow: hidden;
  z-index: 3;
  cursor: pointer;
  background: ${COLORS.white};

  ${({ $isActive }) => {
    if ($isActive) {
      return css`
        background: ${COLORS.mint};

        ${Title} {
          color: ${COLORS.white};
        }

        ${TextContent} {
          display: block;
          color: ${COLORS.white};
        }

        ${TopContent} {
          padding-bottom: ${apx.d(18)};
        }
      `;
    }
  }}

  @media (max-width: ${BreakpointsEnum.tablet}px) {
    border-radius: ${apx.l(8)};

    ${Title} {
      ${TYPOGRAPHY.bodyBold16};
    }

    ${TextContent} {
      ${TYPOGRAPHY.bodyMedium14};
    }

    ${({ $isActive }) => {
      if ($isActive) {
        return css`
          height: auto;
        `;
      }
    }}
  }

  @media (max-width: ${BreakpointsEnum.mb}px) {
    border-radius: ${apx.m(8)};

    ${Title} {
      ${TYPOGRAPHY.bodyBold16};
      font-size: ${apx.d(18)};
      line-height: 125%;
    }

    ${TextContent} {
      ${TYPOGRAPHY.bodyMedium14};
      font-size: ${apx.d(13)};
      padding-left: ${apx.d(25)};
    }
  }
`;
