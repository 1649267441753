import { FC } from 'react';
import styled from 'styled-components';
import { API_URL, COLORS, TYPOGRAPHY } from '@landing/constants';
import { stagesList } from './lib/data';
import Container from '../Container';
import { BreakpointsEnum } from '@landing/types';
import { ButtonLink } from '@landing/ui/components';
import flower from '@landing/assets/images/flower.svg';
import smallDecorativeLeaf from '@landing/assets/images/team-bg-leaf.svg';
import apx from '@landing/constants/adaptivepx';

const TeamSection: FC = () => {
  return (
    <div style={{ scrollMargin: 100 }} id="team">
      <Root>
        <StyledContainer>
          <Title>Как мы отбираем команду?</Title>
          <List>
            {stagesList.map((item, index) => (
              <Item key={index}>
                <div>
                  <ItemNumber>{item.number}</ItemNumber>
                </div>
                <Content>
                  <ItemHeading>{item.heading}</ItemHeading>
                  <ItemSubheading>
                    {item.subheading && item.subheading}
                  </ItemSubheading>

                  {item.lists && (
                    <ItemUl>
                      {item.lists.map((list, index) => (
                        <ItemList key={index}>{list.content}</ItemList>
                      ))}
                    </ItemUl>
                  )}
                </Content>
              </Item>
            ))}
          </List>
          <ButtonWrap>
            <StyledButtonLink isLarge href={API_URL.allTherapists}>
              Выбрать специалиста
            </StyledButtonLink>
          </ButtonWrap>
        </StyledContainer>
        <FlowerWrap $bgImg={flower.src} />
        <LeafWrap $bgImg={smallDecorativeLeaf.src} />
      </Root>
    </div>
  );
};

export default TeamSection;

const Root = styled.section`
  background: #ecf7f6;
  position: relative;
  overflow: hidden;

  @media (max-width: ${BreakpointsEnum.md}px) {
  }
`;

const ButtonWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  margin-top: ${apx.d(44)};

  @media (max-width: ${BreakpointsEnum.tablet}px) {
    margin-top: ${apx.l(48)};
  }

  @media (max-width: ${BreakpointsEnum.mb}px) {
    justify-content: center;
    margin-top: ${apx.m(30)};
  }
`;

const StyledButtonLink = styled(ButtonLink)`
  padding: ${apx.d(20)} ${apx.d(70)};
  border-radius: ${apx.d(90)};

  @media (max-width: ${BreakpointsEnum.tablet}px) {
    padding: ${apx.l(15)} ${apx.l(52)};
    height: ${apx.l(52)};
    border-radius: ${apx.l(67)};

    font-size: ${apx.l(18)};
    line-height: ${apx.l(21)};
  }

  @media (max-width: ${BreakpointsEnum.mb}px) {
    padding: ${apx.m(14)} ${apx.m(38)};
    height: ${apx.m(50)};
    border-radius: ${apx.m(42)};

    font-size: ${apx.m(18)};
    line-height: ${apx.m(21)};
  }
`;

const StyledContainer = styled(Container)`
  z-index: 2;
  padding-top: ${apx.d(78)};
  padding-bottom: ${apx.d(69)};

  @media (max-width: ${BreakpointsEnum.tablet}px) {
    padding-top: ${apx.l(56)};
    padding-bottom: ${apx.l(84)};
  }

  @media (max-width: ${BreakpointsEnum.mb}px) {
    padding-top: ${apx.m(32)};
    padding-bottom: ${apx.m(155)};
  }
`;

const Title = styled.h2`
  margin-bottom: ${apx.d(62)};
  ${TYPOGRAPHY.bodyBold48};
  color: ${COLORS.nativeBlack};
  line-height: 130%;

  @media (max-width: ${BreakpointsEnum.tablet}px) {
    margin-bottom: ${apx.l(47)};
    font-size: ${apx.l(36)};
  }

  @media (max-width: ${BreakpointsEnum.s}px) {
    width: 100%;

    margin-bottom: ${apx.m(29)};
    font-size: ${apx.m(30)};
  }
`;

const Item = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  align-items: flex-start;

  &:nth-of-type(-n + 2) {
    margin-bottom: ${apx.d(42)};
  }

  &:nth-child(2) div:nth-child(2) {
    position: relative;
  }

  &:nth-child(2) div:nth-child(2):before {
    content: '';
    width: ${apx.d(279)};
    height: ${apx.d(30)};
    background: ${COLORS.lightYellow100};
    position: absolute;
    z-index: -1;
    left: -${apx.d(54)};
  }

  @media (max-width: ${BreakpointsEnum.tablet}px) {
    &:nth-of-type(-n + 2) {
      margin-bottom: ${apx.l(61)};
    }

    &:nth-child(2) div:nth-child(2) {
      position: relative;
    }

    &:nth-child(2) div:nth-child(2):before {
      content: '';
      width: ${apx.l(209)} !important;
      height: ${apx.l(22)} !important;
      background: ${COLORS.lightYellow100};
      position: absolute;
      z-index: -1;
      left: -${apx.l(44)};
      top: -${apx.l(5)};
    }
  }

  @media (max-width: ${BreakpointsEnum.mb}px) {
    &:nth-of-type(-n + 2) {
      margin-bottom: 0;
    }

    &:nth-child(2) div:nth-child(2):before {
      content: '';
      width: ${apx.m(185)} !important;
      height: ${apx.m(24)} !important;
      background: ${COLORS.lightYellow100};
      position: absolute;
      z-index: -1;
      left: -${apx.m(36)};
      top: -${apx.m(5)};
    }
  }
`;
const Content = styled.div``;

const List = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 49px;

  ${Item}:nth-child(1) ${Content} {
    max-width: ${apx.d(550)};
  }

  ${Item}:nth-child(2) ${Content} {
    max-width: ${apx.d(420)};
  }

  ${Item}:nth-child(3) ${Content} {
    max-width: ${apx.d(533)};
  }

  ${Item}:nth-child(4) ${Content} {
    max-width: ${apx.d(520)};
  }

  @media (max-width: ${BreakpointsEnum.tablet}px) {
    column-gap: ${apx.l(20)};

    ${Item}:nth-child(1) ${Content} {
      max-width: ${apx.l(323)};
    }

    ${Item}:nth-child(2) ${Content} {
      max-width: ${apx.l(305)};
    }

    ${Item}:nth-child(3) ${Content} {
      max-width: ${apx.l(323)};
    }

    ${Item}:nth-child(4) ${Content} {
      max-width: ${apx.l(283)};
    }
  }

  @media (max-width: ${BreakpointsEnum.mb}px) {
    column-gap: ${apx.m(20)};
    display: flex;
    flex-direction: column;
    gap: ${apx.m(19)};

    ${Item} ${Content} {
      width: 100%;
      max-width: none !important;
    }

    ${Item}:nth-child(1) {
      order: 1;
    }

    ${Item}:nth-child(2) {
      order: 3;
    }

    ${Item}:nth-child(3) {
      order: 2;
    }

    ${Item}:nth-child(4) {
      order: 4;
    }
  }
`;

const ItemHeading = styled.p`
  ${TYPOGRAPHY.bodyBold26};
  color: ${COLORS.nativeBlack200};
  margin-bottom: ${apx.d(12)};

  @media (max-width: ${BreakpointsEnum.tablet}px) {
    font-size: ${apx.l(19)};
    margin-bottom: ${apx.l(9)};
  }

  @media (max-width: ${BreakpointsEnum.mb}px) {
    font-size: ${apx.m(18)} !important;
    margin-bottom: ${apx.m(5)};
    line-height: ${apx.m(18)};
  }
`;

const ItemSubheading = styled.p`
  ${TYPOGRAPHY.bodyMedium18};
  line-height: ${apx.d(30)};
  color: ${COLORS.nativeBlack200};
  margin-left: -${apx.d(20)};

  @media (max-width: ${BreakpointsEnum.tablet}px) {
    font-size: ${apx.l(13)};
    line-height: 165%;
    margin-left: -${apx.l(25)};
  }

  @media (max-width: ${BreakpointsEnum.mb}px) {
    font-size: ${apx.m(13)};
    margin-left: -${apx.m(28)};
  }
`;

const ItemNumber = styled.p`
  position: relative;
  margin-right: ${apx.d(10)};
  ${TYPOGRAPHY.bodyBold26};
  color: ${COLORS.nativeBlack200};
  z-index: 0;

  @media (max-width: ${BreakpointsEnum.tablet}px) {
    font-size: ${apx.l(22)};
    margin: 0;
    margin-right: ${apx.l(13)};
  }

  @media (max-width: ${BreakpointsEnum.mb}px) {
    font-size: ${apx.m(18)};
    margin-left: ${apx.m(10)};
    line-height: ${apx.m(19)};
  }
`;

const ItemUl = styled.ul`
  @media (max-width: ${BreakpointsEnum.s}px) {
    margin-left: -${apx.d(10)} !important;
  }
`;

const ItemList = styled.li`
  list-style: disc;
  ${TYPOGRAPHY.bodyMedium18};
  line-height: ${apx.d(30)};
  color: ${COLORS.nativeBlack200};

  @media (max-width: ${BreakpointsEnum.tablet}px) {
    font-size: ${apx.l(13)};
    line-height: 165%;
  }

  @media (max-width: ${BreakpointsEnum.mb}px) {
    font-size: ${apx.m(13)};
    line-height: 165%;
  }
`;

const FlowerWrap = styled.div<{ $bgImg: string }>`
  width: 382px;
  height: 336px;
  background-image: ${({ $bgImg }) => `url(${$bgImg})`};
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  position: absolute;
  right: 0;
  bottom: 0;

  @media (max-width: ${BreakpointsEnum.sm}px) {
    width: 254px;
    height: 264px;
    right: 20px;
    left: auto;
    bottom: -82px;
    margin: auto;
  }

  @media (max-width: ${BreakpointsEnum.s}px) {
    width: 254px;
    height: 254px;
    right: 0;
    left: 0;
    bottom: -92px;
    margin: auto;
  }
`;

const LeafWrap = styled.div<{ $bgImg: string }>`
  width: 147px;
  height: 81px;
  background-image: ${({ $bgImg }) => `url(${$bgImg})`};
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  position: absolute;
  left: 0;
  top: 0;

  @media (max-width: ${BreakpointsEnum.sm}px) {
    width: 74px;
    height: 38px;
    right: -7px;
    top: -11px;
  }
`;
